import api from './index';

const productionApi = {
    async getProductions(filters = "") {
        return await api.get('/productions' + filters);
    },

    async getProduction(id: string) {
        return await api.get(`/productions/${id}`);
    },

    async getShoot(id: string) {
        return await api.get(`/shoots/${id}`);
    },

    async postShootTimecard(id: string, data: object) {
        return await api.post(`/shoots/${id}`, data);
    },

    async postShootCheckin(id: string, data: object) {
        return await api.post(`/shoots/${id}/checkin`, data);
    },

    async getFeed() {
        return await api.get(`/feed?v2`);
    },

    async getSchedule() {
        return await api.get(`/schedule`);
    },

    async getUnavailabilities(id: number) {
        return await api.get(`/unavailabilities`);
    },

    async postUnavailabilities(data: object) {
        return await api.post(`/unavailabilities`, data);
    },

    async getPublicOffer(id: string) {
        return await api.get(`/offer-summary/${id}`);
    },

    async rejectPublicOffer(id: string, openToNegotiate: boolean) {
        return await api.post(`/offer-summary/${id}/reject`, {open_to_negotiation: openToNegotiate});
    },

    async acceptPublicOffer(id: string, data: object) {
        return await api.post(`/offer-summary/${id}/accept`, data);
    },

    async getCrewOffers() {
        return await api.get(`/offers`);
    },

    async getCrewOffer(id: string) {
        return await api.get(`/offers/${id}`);
    },

    async getTimecardList(filters = "") {
        return await api.get(`/timecards${filters}`);
    },

    async getTimecard(id: string) {
        return await api.get(`/timecards/${id}`);
    },

    async postBonuses(id: string, data: FormData) {
        return await api.postFormData(`/timecards/${id}/bonuses`, data);
    },

    async getPaystubList(filters = "") {
        return await api.get(`/paystubs${filters}`);
    },

    async getPaystub(id: string) {
        return await api.get(`/paystubs/${id}`);
    },

    async getYearlyPaystub(year: string) {
        return await api.get(`/yearly-paystubs/${year}`);
    },

    async approveTimecard(id: string) {
        return await api.post(`/timecards/${id}/approve`, {});
    },

    async rejectCrewOffer(id: string) {
        return await api.post(`/offers/${id}/reject`, {});
    },

    async acceptCrewOffer(id: string, data: object) {
        return await api.post(`/offers/${id}/accept`, data);
    },

    async getOfferDocuments(id: string) {
        return await api.get(`/offers/${id}/documents`);
    },

    async getTaxForms() {
        return await api.get(`/documents?type=w2`);
    },

    async getPotentialI9Documents(id: string) {
        return await api.get(`/offers/${id}/i9-settings`);
    },

    async postOfferUploadI9(id: string, data: FormData) {
        return await api.postFormData(`/offers/${id}/i9-upload`, data);
    },

    async postOfferUploadDocument(id: string, data: FormData) {
        return await api.postFormData(`/offers/${id}/documents/upload`, data);
    },

    async postConfirmDocuments(id: string, data: object) {
        return await api.post(`/offers/${id}/documents/confirm`, data);
    },

    async getSignDocumentURL(id: string, data: object) {
        return await api.post(`/offers/${id}/documents/sign`, data);
    },
}

export default productionApi;