import React, {useEffect, useState} from "react";

import {TouchableOpacity, View} from "react-native";
import CheckRounded from "@assets/icons/check-rounded.svg";

import Text from "@components/General/Text";
import Flex from "@components/Views/Flex";
import Button from "@components/Buttons/Button";
import Header from "@components/Views/Header";
import FlexRow from "@components/Views/FlexRow";
import {transformTailwindToHex} from "@src/helpers";
import {extraCrewData} from "@pages/SignedIn/Offer/ExtraCrewData";

type ExtraCrewDataStep1Props = {
    data: extraCrewData,
    updateData: (data: {}) => void
    showBackButton: boolean,
}

export default function ExtraCrewDataStep1(props: ExtraCrewDataStep1Props) {
    const [directDeposit, setDirectDeposit] = useState<number>(1);

    useEffect(() => {
        console.log(props);
    }, []);

    const goToNextStep = () => {
        props.updateData({
            direct_deposit: directDeposit,
        });
    }

    return (
        <Flex className="flex-1" verticalAlign="content-between">

            <Header title="Extra Data for Offer" showBack={props.showBackButton}/>

            <Flex className="px-5 gap-y-2 pb-5 bg-navy-light-100 pt-5">
                <Text type="sm-semibold" className="mb-2" color="white">Confirm Payment Preference</Text>
                <Text type="xs-normal" className="opacity-70 pt-2" color="white">Choose how you would like to receive payments from FilmUp. You can always change this later in Settings.</Text>
            </Flex>

            <Flex className="flex-1 bg-white" verticalAlign="between">

                <Flex className="p-5 h-full" verticalAlign="top">
                    <Flex className="flex-1 gap-y-2">
                        <Flex className="w-full space-y-2 divide-y divide-denim-20">
                            <TouchableOpacity onPress={() => setDirectDeposit(1)}>
                                <FlexRow className={`p-2 py-3 rounded-lg`}>
                                    {directDeposit == 1 && <CheckRounded width={16} height={16} fill={transformTailwindToHex("navy-light-100")}/>}
                                    {directDeposit == 0 && <View className="h-4 w-4 border border-denim-50 rounded-full"/>}
                                    <Text type="xs-semibold" className="ml-2" color={directDeposit == 1 ? "navy-light-100" : "denim-50"}>Direct Deposit (default)</Text>
                                </FlexRow>
                            </TouchableOpacity>
                            <TouchableOpacity onPress={() => setDirectDeposit(0)}>
                                <FlexRow className={`p-2 py-3 rounded-lg`}>
                                    {directDeposit == 0 && <CheckRounded width={16} height={16} fill={transformTailwindToHex("navy-light-100")}/>}
                                    {directDeposit == 1 && <View className="h-4 w-4 border border-denim-50 rounded-full"/>}
                                    <Text type="xs-semibold" className="ml-2" color={directDeposit == 0 ? "navy-light-100" : "denim-50"}>Physical Check</Text>
                                </FlexRow>
                            </TouchableOpacity>
                        </Flex>

                    </Flex>

                    <Flex className="rounded-3xl px-5 py-3 bg-white shadow-lg gap-y-2 pb-5">
                        <Button
                            useFullColor={true}
                            value="Next"
                            className="mt-2"
                            onPress={() => goToNextStep()}
                        />
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
}
