import React, {useEffect, useState} from "react";

import {TouchableOpacity, View} from "react-native";
import CheckRounded from "@assets/icons/check-rounded.svg";

import Text from "@components/General/Text";
import Flex from "@components/Views/Flex";
import Button from "@components/Buttons/Button";
import Header from "@components/Views/Header";
import FlexRow from "@components/Views/FlexRow";
import {maskNumberInput, transformTailwindToHex} from "@src/helpers";
import TextInput from "@components/Forms/TextInput";
import {extraCrewData} from "@pages/SignedIn/Offer/ExtraCrewData";

type LoanOutTypes = "corp" | "llc-single" | "llc-multi" | "partnership";
type ExtraCrewDataStep2Props = {
    data: extraCrewData,
    employmentType: string,
    updateData: (data: {}) => void
    loading: boolean,
    showBackButton: boolean
}

export default function ExtraCrewDataStep2(props: ExtraCrewDataStep2Props) {
    const loanOutOptions: Record<LoanOutTypes, string> = {
        "corp": "Corporation",
        "llc-single": "Single Member LLC",
        "llc-multi": "Multi Member LLC",
        "partnership": "Partnership",
    }
    const [loanOut, setLoanOut] = useState<LoanOutTypes | "">("");
    const [ein, setEin] = useState<string>("");
    const [secureEin, setSecureEin] = useState<string>("");

    const goToNextStep = () => {
        props.updateData({
            company_type: loanOut,
            ein: ein
        });
    }

    return (
        <Flex className="flex-1" verticalAlign="content-between">

            <Header title="Extra Data for Offer" showBack={props.showBackButton}/>

            <Flex className="px-5 gap-y-2 pb-5 bg-navy-light-100 pt-5">
                <Text type="sm-semibold" color="white">Loan-Out Business Classification</Text>
                <Text type="xs-normal" className="opacity-70 pt-2" color="white">Select the business structure that your Loan Out organization operates under.</Text>
            </Flex>

            <Flex className="flex-1 bg-white" verticalAlign="between">

                <Flex className="flex-1 px-5">

                    {props.employmentType != 'w2' &&
                            <View className="mt-5">
                                <TextInput
                                    label="EIN"
                                    keyboardType='numeric'
                                    onChange={(value) => {
                                        let maskedValue = maskNumberInput(value, '99-9999999', true, ein);

                                        setEin(maskedValue.text)
                                        setSecureEin(maskedValue.secureText)
                                    }}
                                    value={secureEin}
                                />
                            </View>
                        }

                    <Flex className="flex-1 mt-5">
                        {Object.keys(loanOutOptions).map((key, index) =>
                            <TouchableOpacity onPress={() => {
                                setLoanOut(key as LoanOutTypes)
                            }}>
                                <FlexRow className="py-3.5 border-b-0.5 border-denim-20" verticalAlign="center">
                                    {loanOut == key &&
                                        <CheckRounded width={16} height={16} fill={transformTailwindToHex("navy-light-100")}/>}
                                    {loanOut != key && <View className="h-4 w-4 border border-navy-light-100 rounded-full"/>}

                                    <Flex className="ml-4">
                                        <Text type="xs-medium">
                                            {loanOutOptions[key as LoanOutTypes]}
                                        </Text>
                                    </Flex>
                                </FlexRow>
                            </TouchableOpacity>
                        )}
                    </Flex>
                </Flex>

                <Flex className="rounded-3xl px-5 py-3 bg-white shadow-lg gap-y-2 pb-5">
                    <Button
                        useFullColor={true}
                        value="Next"
                        className="mt-2"
                        loading={props.loading}
                        onPress={() => goToNextStep()}
                        disabled={loanOut == ""}
                    />
                </Flex>
            </Flex>
        </Flex>
    )
}
