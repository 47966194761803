import React, {useEffect, useState} from "react";
import {Linking, TouchableOpacity} from "react-native";
import TextInput from "@components/Forms/TextInput";

import Text from "@components/General/Text";
import Flex from "@components/Views/Flex";
import Button from "@components/Buttons/Button";
import FlexRow from "@components/Views/FlexRow";
import Header from "@components/Views/Header";
import Checkbox from 'expo-checkbox';
import {transformTailwindToHex} from "@src/helpers";
import AlertCircle from "@assets/icons/alert-circle.svg";
import {signUpData} from "@pages/SignedOut/GettingStarted";

type GettingStartedStep1Props = {
    data: signUpData,
    updateData: (data: {}) => void
}

export default function GettingStartedStep1(props: GettingStartedStep1Props) {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [isChecked, setChecked] = useState(false);

    useEffect(() => {
        updateDataBasedOnProps();
    }, []);

    const updateDataBasedOnProps = () => {
        setEmail(props.data.email ?? "");
        setPassword(props.data.password ?? "");
        setConfirmPassword(props.data.confirm_password ?? "");
    }

    const goToNextStep = () => {
        props.updateData({
            email: email,
            password: password,
            confirmPassword: password
        });
    }

    return (
        <Flex className="flex-1" verticalAlign="content-between">

            <Header title="Getting Started" showBack={false}/>

            <Flex className="px-5 gap-y-2 pb-5 bg-navy-light-100 pt-5">
                <Text type="sm-semibold" color="white">Setup Your FilmUp Account</Text>
                <Text type="xs-normal" className="opacity-70 pt-2" color="white">Confirm the email address and password that you would like to use to access your FilmUp account.</Text>
            </Flex>

            <Flex className="flex-1 bg-white" verticalAlign="between">
                <Flex className="flex-1 p-5 gap-y-2">

                    {(password.length < 6 || password != confirmPassword) &&
                        <FlexRow verticalAlign="center" align="start" className="p-2 bg-coral-10 rounded-xl border border-coral-100 mb-5">
                            <AlertCircle width={16} height={16} fill={transformTailwindToHex("coral-100")}/>
                            <Text type="xs-medium" color="coral-100" className="ml-2">Password needs to have more than 6 characters and 'Confirm Password' needs to match it.</Text>
                        </FlexRow>
                    }

                    <TextInput label="Email" onChange={setEmail} value={email}/>
                    <TextInput label="Password" password={true} onChange={setPassword} value={password}/>
                    <TextInput label="Confirm Password" password={true} onChange={setConfirmPassword} value={confirmPassword}/>

                    <FlexRow verticalAlign="center" className="pt-2">
                        <Checkbox value={isChecked} onValueChange={setChecked}/>
                        <Text type="xs-bold" className="ml-3">I accept FilmUp's
                            <TouchableOpacity onPress={() => Linking.openURL("https://dashboard.filmup.co/service-terms")}>
                                <Text type="xs-bold" color="navy-light-100"> Terms of Use </Text>
                            </TouchableOpacity>
                            <Text type="xs-bold">and</Text>
                            <TouchableOpacity onPress={() => Linking.openURL("https://dashboard.filmup.co/privacy-policy")}>
                                <Text type="xs-bold" color="navy-light-100"> Privacy Policy </Text>
                            </TouchableOpacity>
                        </Text>
                    </FlexRow>

                </Flex>

                <Flex className="rounded-3xl px-5 py-3 bg-white shadow-lg gap-y-2 pb-5">
                    <Button
                        useFullColor={true}
                        value="Next"
                        className="flex-grow"
                        onPress={() => goToNextStep()}
                        disabled={!isChecked || password == "" || password.length < 6 || password != confirmPassword || confirmPassword == "" || email == ""}
                    />
                </Flex>

            </Flex>
        </Flex>
    )
}
