import React, {useState} from "react";

import {StackScreenProps} from "@react-navigation/stack";
import {AuthStackParamList} from "@src/routes";

import Flex from "@components/Views/Flex";
import Header from "@components/Views/Header";
import Text from "@components/General/Text";

import FlexRow from "@components/Views/FlexRow";
import Step1 from "@pages/SignedIn/ProductionInvoice/components/Step1";
import Step2 from "@pages/SignedIn/ProductionInvoice/components/Step2";
import Step3 from "@pages/SignedIn/ProductionInvoice/components/Step3";
import Step4 from "@pages/SignedIn/ProductionInvoice/components/Step4";
import Review from "@pages/SignedIn/ProductionInvoice/components/Review";

const headerContent = {
    step1: {
        title: "Pay Period",
        subtitle: "Select which pay period this work was performed on. ",
        step: "1 of 4"
    },
    step2: {
        title: "Working Hours",
        subtitle: "Add your working hours for each of the days below.",
        step: "2 of 4"
    },
    step3: {
        title: "Payments & Expenses (optional)",
        subtitle: "Add any other payments or expenses for this week.",
        step: "3 of 4"
    },
    step4: {
        title: "Additional Materials (optional)",
        subtitle: "Add an invoice note and any additional documents.",
        step: "4 of 4"
    },
    review: {
        title: "Final Review",
        subtitle: "Please review your invoice and submit when ready.",
        step: null
    },
}

type ProductionInvoiceProps = StackScreenProps<AuthStackParamList, 'ProductionInvoice'>;
export default function ProductionInvoice({navigation, route}: ProductionInvoiceProps) {

    const productionId = route.params.productionId;

    const [stage, setStage] = useState<"step1" | "step2" | "step3" | "step4" | "review">("step1");
    const [prevStage, setPrevStage] = useState<"step1" | "step2" | "step3" | "step4" | "review">("step1");

    return (
        <Flex className="flex-1" verticalAlign="content-between">

            <Header title="Create New Invoice"/>

            <Flex className="px-5 gap-y-2 pb-5 bg-navy-light-100 pt-5">
                <FlexRow align="between">
                    <Text type="sm-semibold" color="white">{headerContent[stage].title}</Text>
                    <Text type="xs-semibold" color="white">{headerContent[stage].step}</Text>
                </FlexRow>
                <Text type="xs-normal" className="opacity-70 mt-2" color="white">{headerContent[stage].subtitle}</Text>
            </Flex>

            <Flex className="flex-1 bg-white" verticalAlign="between">
                {stage == "step1" && <Step1 productionId={productionId} nextStep={() => {
                    setPrevStage("step1")
                    setStage("step2")
                }}/>}
                {stage == "step2" && <Step2 productionId={productionId} nextStep={() => {
                    setPrevStage("step2")
                    setStage("step3")
                }}/>}
                {stage == "step3" && <Step3 productionId={productionId} nextStep={() => {
                    setPrevStage("step3")
                    setStage("step4")
                }}/>}
                {stage == "step4" && <Step4 productionId={productionId} nextStep={() => {
                    setPrevStage("step4")
                    setStage("review")
                }}/>}
                {stage == "review" && <Review productionId={productionId}/>}
            </Flex>
        </Flex>
    );
}
