import React, {useEffect, useState} from "react";
import {ActivityIndicator, ScrollView, TouchableOpacity,} from "react-native";

import Flex from "@components/Views/Flex";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import Text from "@components/General/Text";
import FlexRow from "@components/Views/FlexRow";
import {capitalizeFirstLetter, formatDate, formatDateDefault, formatDateDefaultYear, formatTimecardtime, getDatesBetweenDates, normalizeStatusString, toTwoDecimal, transformTailwindToHex, translatedAmount} from "@src/helpers";
import productionApi from "@api/productionApi";
import moment from "moment";
import Content from "@components/General/Content";
import Button from "@components/Buttons/Button";
import FloatingBottom from "@components/Views/FloatingBottom";
import AlertCircle from "@assets/icons/alert-circle.svg";

export default function WeekView({timecardId, goToDate}: { timecardId: string, goToDate: (date: string) => void }) {
    const insets = useSafeAreaInsets();
    const [loading, setLoading] = useState<boolean>(true);
    const [timecard, setTimecard] = useState<any>(null);

    useEffect(() => {
        getTimecard(timecardId);
    }, [timecardId]);

    const getTimecard = async (timecardId: string) => {
        setLoading(true);

        let response = await productionApi.getTimecard(timecardId);
        setTimecard(response.data);

        setLoading(false);
    };

    const approveTimecard = async () => {
        setLoading(true);
        await productionApi.approveTimecard(timecardId);
        getTimecard(timecardId);
    };

    const dayTimeCard = (date: string) => {

        let data = timecard.attributes.times.find((timecardData: any) => {
            return timecardData.date == moment(date).format("YYYY-MM-DD");
        });

        if (data != undefined) {
            data = JSON.parse(JSON.stringify(data))

            data.hourDiff = data.work_duration
            data.checkin_time = data.checkin_time != null ? formatTimecardtime(data.checkin_time) : "Add";
            data.meal1 = data.meal1?.start_time != null ? formatTimecardtime(data.meal1?.start_time) : data.meal1?.penalty ? "Skipped" : data.required_meals_count > 0 ? "Add" : "";
            data.meal2 = data.meal2?.start_time != null ? formatTimecardtime(data.meal2?.start_time) : data.meal2?.penalty ? "Skipped" : data.required_meals_count == 2 ? "Add" : "";
            data.checkout_time = data.checkout_time != null ? formatTimecardtime(data.checkout_time) : "Add";
            data.missing_data = data.checkin_time == "Add" || data.meal1 == "Add" || data.meal2 == "Add" || data.checkout_time == "Add";
            data.nothingScheduled = false;

        } else {
            data = {
                checkin_time: null,
                checkout_time: null,
                meal1: null,
                meal2: null,
                hourDiff: null,
                missing_data: false,
                nothingScheduled: true
            }
        }

        return data;
    }

    const statusColor = (status: string) => {
        switch (status) {
            case "scheduled":
                return "navy-light-100";
            case "incomplete":
            case "ready_to_approve":
                return "coral-100";
            case "approved":
                return "emerald-100";
            default:
                return "orange-100";
        }
    }

    const renderTimecard = () => {

        return <Flex className="mt-5" verticalAlign="center">
            <FlexRow align="between" className="border-b-0.5 border-denim-20 -mx-5 px-5 h-5">
                <Text type="xs-medium" color="denim-50" className="flex-1">In</Text>
                <Text type="xs-medium" color="denim-50" className="flex-1">Meal 1</Text>
                <Text type="xs-medium" color="denim-50" className="flex-1">Meal 2</Text>
                <Text type="xs-medium" color="denim-50" className="flex-1">Out</Text>
                <Flex className="flex-1" align="end">
                    <Text type="xs-medium" color="denim-50">Hours</Text>
                </Flex>
            </FlexRow>

            {getDatesBetweenDates(timecard.attributes.start_date, timecard.attributes.end_date).map((date: any) => {

                let times = dayTimeCard(date);

                return <TouchableOpacity onPress={() => goToDate(date)} disabled={times.nothingScheduled}>
                    <Flex className={`h-11 border-b-0.5 border-denim-20 ${times.missing_data ? "bg-coral-100/5 -mx-5 px-5" : ""}`} verticalAlign="center">
                        <FlexRow align="between">
                            <Text type="2xs-normal" color="denim-50">{formatDate(date, "dddd").substr(0, 3)}</Text>
                            <Text type="2xs-normal" color="denim-50">{formatDateDefault(date)}</Text>
                        </FlexRow>
                        {!times.nothingScheduled && <FlexRow>
                            <Text type="xs-medium" color={times.checkin_time == "Add" ? "coral-100" : "denim-100"} className="flex-1">{times.checkin_time}</Text>
                            <Text type="xs-medium" color={times.meal1 == "Add" ? "coral-100" : "denim-100"} className="flex-1">
                                {times.meal1}
                            </Text>
                            <Text type="xs-medium" color={times.meal2 == "Add" ? "coral-100" : "denim-100"} className="flex-1">
                                {times.meal2}
                            </Text>
                            <Text type="xs-medium" color={times.checkout_time == "Add" ? "coral-100" : "denim-100"} className="flex-1">{times.checkout_time}</Text>
                            <Flex className="flex-1" align="end">
                                <Text type="xs-medium" color={timecard.attributes.status == "approved" ? "emerald-100" : "navy-light-100"}>{toTwoDecimal(times.hourDiff)}</Text>
                            </Flex>
                        </FlexRow>}

                        {times.nothingScheduled && <Text type="xs-medium" color="denim-20">Nothing Scheduled</Text>}
                    </Flex>
                </TouchableOpacity>
            })}

            <FlexRow verticalAlign="center" align="end" className="mt-2">
                <Text type="xs-medium" className="mr-1" color={timecard.attributes.status == "approved" ? "emerald-100" : "navy-light-100"}>Total Hours:</Text>
                <Text type="xs-medium" color={timecard.attributes.status == "approved" ? "emerald-100" : "navy-light-100"}>{toTwoDecimal(timecard.attributes.total_duration)}</Text>
            </FlexRow>
        </Flex>
    }

    if (loading) {
        return <Flex className="flex-1"><ActivityIndicator className="mt-5"/></Flex>
    }

    if (timecard == null) {
        return <Flex className="flex-1 pt-5" align="center">
            <Text type="xs-semibold" color="denim-50">
                No data to display
            </Text>
        </Flex>
    }

    return <>
        <ScrollView
            className="flex-1 px-5"
            style={{paddingBottom: insets.bottom == 0 ? 20 : insets.bottom}}>

            <FlexRow className="mt-5" align="between">
                <Text type="sm-semibold" color="navy-100">
                    {formatDateDefault(timecard.attributes.start_date)} - {formatDateDefaultYear(timecard.attributes.end_date)}
                </Text>
                <Text type="sm-semibold" color={statusColor(timecard.attributes.status)}>
                    {normalizeStatusString(timecard.attributes.status)}
                </Text>
            </FlexRow>

            {renderTimecard()}

            <Content title="Other Payments & Expenses" className="mt-8">
                <Flex className="-mt-3">

                    {timecard.related.bonuses.data.length == 0 &&
                        <Flex className="h-11 border-b-0.5 border-denim-20" verticalAlign="center" align="center">
                            <Text type="xs-medium" color="denim-50">No Payments Added</Text>
                        </Flex>
                    }

                    {timecard.related.bonuses.data.map((expense: any) => {
                        return <FlexRow verticalAlign="center" align="between" className="h-11 border-b-0.5 border-denim-20">
                            <FlexRow verticalAlign="center" align="end" className="gap-x-2">
                                <Text type="xs-medium" color="navy-100">{formatDateDefault(expense.attributes.date)}</Text>
                                <Text type="xs-medium" color="denim-50">{expense.attributes.type}</Text>
                            </FlexRow>
                            <Text type="xs-medium" color="denim-100">{translatedAmount(expense.attributes.amount)}</Text>
                        </FlexRow>
                    })}

                    {timecard.related.bonuses.data.length > 0 &&
                        <FlexRow verticalAlign="center" align="end" className="mt-2">
                            <Text type="xs-medium" className="mr-1" color={timecard.attributes.status == "approved" ? "emerald-100" : "navy-light-100"}>Total Payments:</Text>
                            <Text type="xs-medium" color={timecard.attributes.status == "approved" ? "emerald-100" : "navy-light-100"}>
                                {translatedAmount(timecard.related.bonuses.data.reduce((currentValue, expense) => {
                                    return expense.attributes.amount + currentValue
                                }, 0))}
                            </Text>
                        </FlexRow>
                    }
                </Flex>
            </Content>

            {timecard.attributes.approved_at != null && <Text type="xs-medium" color="denim-50" className="text-center">If you made a mistake please reach out to your Production Contact.</Text>}

        </ScrollView>

        {timecard.attributes.approved_at == null && timecard.attributes.incomplete_days_count == 0 &&
            <FloatingBottom>
                <Button useFullColor={true} value="Approve Timecard" color="navy-light-100" onPress={() => approveTimecard()}/>
                <FlexRow verticalAlign="center" align="start" className="p-2 bg-coral-10 rounded-xl border border-coral-100 mt-2">
                    <AlertCircle width={16} height={16} fill={transformTailwindToHex("coral-100")}/>
                    <Text type="xs-medium" color="coral-100" className="ml-2">If you have questions about your timecard please reach out to your Production Contact.</Text>
                </FlexRow>
            </FloatingBottom>}
    </>
}