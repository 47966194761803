import {useSafeAreaInsets} from "react-native-safe-area-context";
import Flex from "@components/Views/Flex";
import Header from "@components/Views/Header";
import ContentTabs from "@components/General/ContentTabs";
import SubContentTabs from "@components/General/SubContentTabs";
import {StackScreenProps} from "@react-navigation/stack";
import {AuthStackParamList} from "@src/routes";
import {productionType} from "@interfaces/ProductionInterface";
import {useEffect, useState} from "react";
import {useFocusEffect} from "@react-navigation/native";
import React from "react";
import productionApi from "@api/productionApi";
import YourRoleTab from "@pages/SignedIn/Production/components/YourRoleTab";
import ProductionTab from "@pages/SignedIn/Production/components/ProductionTab";
import PaystubsTab from "@pages/SignedIn/Production/components/PaystubsTab";
import TimecardTab from "@pages/SignedIn/Production/components/TimecardTab";
import InvoiceTab from "@pages/SignedIn/Production/components/InvoiceTab";
import {ActivityIndicator, SafeAreaView} from "react-native";
type ProductionProps = StackScreenProps<AuthStackParamList, 'Production'>;
export default function Production({ navigation, route }: ProductionProps) {

    const insets = useSafeAreaInsets();
    const [production, setProduction] = useState<productionType | null>(null);
    const [tabs, setTabs] = useState<string[]>(["Your Role", "Production", "Timecards", "Paystubs"]);
    const [selectedTab, setSelectedTab] = useState<string>(route.params.tab ?? "your-role");
    const [loading, setLoading] = useState<boolean>(true);

    useFocusEffect(
        React.useCallback(() => {
            getProduction();
        }, [])
    );

    useEffect(() => {
        navigation.setParams({...route.params, tab: selectedTab})
    }, [selectedTab]);

    const getProduction = async () => {
        setLoading(true);
        let response: { data: productionType } = await productionApi.getProduction(route.params.productionId);

        setProduction(response.data);
        setLoading(false);
    };

    if (loading || production == null) {
        return <SafeAreaView className="flex-1 bg-white p-5">
            <ActivityIndicator />
        </SafeAreaView>
    }

    return (
        <Flex className="flex-1 bg-white" style={{paddingBottom: insets.bottom == 0 ? 20 : insets.bottom}}>
            <Header title={production.attributes.name}/>
            <ContentTabs values={tabs} defaultSelected={selectedTab} onSelected={(value) => setSelectedTab(value)} />

            {selectedTab === "your-role" && <YourRoleTab production={production}/>}
            {selectedTab === "production" && <ProductionTab production={production}/>}
            {selectedTab === "timecards" && <TimecardTab production={production}/>}
            {selectedTab === "invoice" && <InvoiceTab production={production}/>}
            {selectedTab === "paystubs" && <PaystubsTab production={production}/>}
        </Flex>
    );
}