import React from "react";
import {View} from "react-native";

type FlexRowProps = {
    align?: string,
    verticalAlign?: string,
    className?: string,
    children: React.ReactNode,
    style?: object
};

export default function Flex(props: FlexRowProps) {
    return <View className={`flex-col justify-${props.verticalAlign} items-${props.align}`} {...props}>
        {props.children}
    </View>;
}