import React, {useEffect} from "react";
import {TouchableOpacity} from "react-native";

import FlexRow from "@components/Views/FlexRow";
import Flex from "@components/Views/Flex";
import Text from "@components/General/Text";

type TabsProps = {
    values: string[],
    defaultSelected: string | null,
    align?: string
    onSelected: (value: string) => void,
    className?: string
};
export default function SubContentTabs({values, align = "between", onSelected, defaultSelected, ...props}: TabsProps) {

    const [selected, setSelected] = React.useState(0);
    const updateSelected = (value: string, index: number) => {
        setSelected(index);
        onSelected(value.toLowerCase());
    };

    useEffect(() => {
        if (defaultSelected != null) {
            const indexDefaultSelected = values.findIndex(value => value.toLowerCase() === defaultSelected.toLowerCase());
            setSelected(indexDefaultSelected)
        }
    }, [])

    const rounded = (index: number) => {
        if (index == 0) return "rounded-l-lg";
        if (index == values.length - 1) return "rounded-r-lg";
        return "";
    };

    const border = (index: number) => {
        if (index == 0) return "";
        return "border-l border-denim-100/10";
    }

    return <FlexRow verticalAlign="center" align={align} className={`w-full bg-navy-100 h-10 px-5 text-white text-white/40 border-b border-white/20`} {...props}>
        {values.map((value, index) =>
            <TouchableOpacity className={`h-10 px-2 border-b ${selected == index ? "border-white" : "border-transparent"}`} onPress={() => updateSelected(value, index)} key={index}>
                <Flex verticalAlign="center" align="center" className="flex-1">
                    <Text type="sm-medium" color={selected == index ? "white" : "white/40"} className="text-center">{value}</Text>
                </Flex>
            </TouchableOpacity>
        )}
    </FlexRow>
}