import React, {useEffect, useRef} from "react";
import {Dimensions, Platform, View} from "react-native";
import Flex from "@components/Views/Flex";
import WebView from "react-native-webview";
import RBSheet from "react-native-raw-bottom-sheet";
import {getGlobal} from "reactn";
import {DeviceType} from "expo-device";

const {height} = Dimensions.get('window');

export default function SignDocsModal({ isVisible, url, onFinish, onCloseModal }:{isVisible: boolean, url: string, onFinish: () => void, onCloseModal: () => void}) {

    const refRBSheet = useRef();
    const iframe = useRef();

    useEffect(() => {
        if (isVisible) {
            refRBSheet.current.open()
        }
    }, [isVisible])

    if (!isVisible) {
        return null;
    }

    return (
        <View className="absolute inset-0 bg-denim-100/70">
            <RBSheet
                onClose={() => onCloseModal()}
                ref={refRBSheet}
                animationType={"fade"}
                height={height*0.9}
                customStyles={{
                    container: {
                        borderTopLeftRadius: 28,
                        borderTopRightRadius: 28,
                        width: getGlobal().deviceType == DeviceType.DESKTOP ? "500px" : "100%",
                        marginHorizontal: getGlobal().deviceType == DeviceType.DESKTOP ? "auto" : 0
                    },
                }}
            >
                <Flex className="flex-1">

                    { Platform.OS === 'web' && <iframe className="flex-1" src={url} ref={iframe} onLoad={(event) => {
                        let href = "docsign";

                        try {
                            href = iframe.current.contentWindow.location.href
                        } catch(e) {}

                        if (href.includes("test-crew.filmup.co") || href.includes("crew.filmup.co") ) {
                            refRBSheet.current.close()
                            onFinish();
                        }
                    }}/> }

                    { Platform.OS !== 'web' && <WebView source={{uri: url}} onNavigationStateChange={(navState) => {
                        if (navState.url.includes("test-crew.filmup.co") || navState.url.includes("crew.filmup.co")) {
                            refRBSheet.current.close()
                            onFinish();
                        }
                    }}/> }
                </Flex>
            </RBSheet>
        </View>
    );
}
