import React, {useRef, useState} from 'react';
import {Dimensions, TouchableOpacity} from 'react-native';
import Flex from "@components/Views/Flex";

import CheckCircle from "@assets/icons/check-circle.svg";
import AlertCircle from "@assets/icons/alert-circle.svg";
import LockCircle from "@assets/icons/lock-circle.svg";

import Text from "@components/General/Text";
import FlexRow from "@components/Views/FlexRow";
import RBSheet from "react-native-raw-bottom-sheet";
import Button from "@components/Buttons/Button";
import {MaskedTextInput} from "react-native-mask-text";
import productionApi from "@api/productionApi";
import {formatTimecardtime, isEmpty, transformTailwindToHex, validateMealTimeSetCorrectly, validateTime} from "@src/helpers";
import {ShootType} from "@interfaces/ProductionInterface";
import {showMessage} from "react-native-flash-message";
import SecondaryButton from "@components/Buttons/SecondaryButton";
import {getGlobal} from "reactn";
import {DeviceType} from "expo-device";
import { TimePicker } from 'react-ios-time-picker';
import TimeInput from "@components/Forms/TimeInput";

const {height} = Dimensions.get('window');

interface TimeCardRowProps {
    label: string,
    value: {
        start_time: string,
        end_time: string,
        penalty: boolean
    } | null
    disabled: boolean
    required: boolean
    locked: boolean,
    shootId: string,
    type: string,
    completedBy: null | string,
    updateTimeCard: (shoot: ShootType) => void;
}

export default function TimeCardMealRow({label, value, disabled, required, locked, shootId, type, completedBy, updateTimeCard}: TimeCardRowProps) {

    const [timeOut, setTimeOut] = useState<string | null>(null);
    const [timeIn, setTimeIn] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedTimeOutType, setSelectedTimeOutType] = useState<string>("");
    const [selectedTimeInType, setSelectedTimeInType] = useState<string>("");
    const [selectedType, setSelectedType] = useState<string>("taken");
    const refRBSheet = useRef();

    const usePreDefinedValue = async () => {
        setLoading(true);
        let response = await productionApi.postShootTimecard(shootId, {
            [type]: {
                penalty: true
            }
        });

        if (response?.data != null) {
            updateTimeCard(response.data);
        }

        setLoading(false);
        refRBSheet.current.close()
    }

    const save = async () => { // @TODO: refact!!!

        if (selectedType == "skipped") {
            usePreDefinedValue();
            return;
        }

        const timecardTimeOut = validateTime(timeOut, selectedTimeOutType);
        const timecardTimeIn = validateTime(timeIn, selectedTimeInType);

        if (timecardTimeOut == null || timecardTimeIn == null || !validateMealTimeSetCorrectly(timecardTimeOut, timecardTimeIn)) {
            showMessage({
                message: "Invalid Time",
                description: "Please enter a valid time",
                type: "danger",
            });

            return;
        }

        setLoading(true);

        let response = await productionApi.postShootTimecard(shootId, {
            [type]: {
                start_time: timecardTimeOut,
                end_time: timecardTimeIn,
                penalty: false
            }
        });

        if (response?.data != null) {
            updateTimeCard(response.data);
        }

        setLoading(false);
        refRBSheet.current.close()
    }

    const textColor = () => {
        return "denim-100"
    }

    const getStringTime = () => {
        if (value?.penalty) {
            return "Meal Skipped"
        }

        return `${formatTimecardtime(value?.start_time)} - ${formatTimecardtime(value?.end_time)}`
    }

    const renderContent = () => {

        if (value != null || locked) {
            return <TouchableOpacity onPress={() => {
                setTimeOut(null);
                setTimeIn(null);
                setSelectedTimeOutType("");
                setSelectedTimeInType("");
                setSelectedType("taken");

                refRBSheet.current.open()
            }} disabled={locked}>
                <FlexRow verticalAlign="center" align="between" className="h-12 border-b border-denim-10">
                    <Flex className="w-24" align="start">
                        <Text type="sm-medium" color="denim-50">{label}</Text>
                    </Flex>
                    <Flex className="flex-1" align="start">
                        <Text type="sm-medium">{getStringTime()}</Text>
                    </Flex>
                    {(completedBy != null && completedBy != "Self") &&
                        <Flex align="end">
                            <LockCircle width={16} height={16} fill={transformTailwindToHex("grape-100")}/>
                        </Flex>
                    }

                    {locked &&
                        <Flex align="end">
                            <LockCircle width={16} height={16} fill={transformTailwindToHex("emerald-100")}/>
                        </Flex>
                    }

                    {!locked && completedBy != null && completedBy == "Self" &&
                        <Flex align="end">
                            <CheckCircle width={16} height={16} fill={transformTailwindToHex("navy-light-100")}/>
                        </Flex>
                    }
                </FlexRow>
            </TouchableOpacity>
        }

        return (
            <TouchableOpacity onPress={() => {
                setTimeOut(null);
                setTimeIn(null);
                setSelectedTimeOutType("");
                setSelectedTimeInType("");
                setSelectedType("taken");

                refRBSheet.current.open()
            }} disabled={disabled}>
                <FlexRow verticalAlign="center" align="between" className="h-12 border-b border-denim-10">
                    <Text type="sm-medium" color={required ? "coral-100" : "denim-50"}>{label}</Text>

                    {required && <Flex align="end">
                        <AlertCircle width={16} height={16} fill={transformTailwindToHex("coral-100")}/>
                    </Flex>}
                </FlexRow>
            </TouchableOpacity>
        );
    }

    const renderDisabledInputs = () => {
        return <>
            <Flex className="opacity-50 my-5">
                <Text type="xs-medium" color="navy-100">Meal Out</Text>
                <FlexRow align="space-between" verticalAlign="center" className="mt-1">
                    <Flex className="flex-1 border rounded-xl h-11 border-denim-20 mr-2 text-center text-[15px] font-medium" align="center" verticalAlign="center">
                        <Text type="sm-medium" color="denim-50">00:00</Text>
                    </Flex>
                    <FlexRow align="space-between" verticalAlign="center">
                        <Flex className="flex-1 border rounded-tl-xl rounded-bl-xl h-11 w-12 border-denim-20 px-1 border-r-0" verticalAlign="center" align="center">
                            <Text type="sm-medium" color="denim-50">AM</Text>
                        </Flex>
                        <Flex className="flex-1 border rounded-tr-xl rounded-br-xl h-11 w-12 border-denim-20 px-1" verticalAlign="center" align="center">
                            <Text type="sm-medium" color="denim-50">PM</Text>
                        </Flex>
                    </FlexRow>
                </FlexRow>
            </Flex>

            <Flex className="opacity-50 my-5">
                <Text type="xs-medium" color="navy-100">Meal In</Text>
                <FlexRow align="space-between" verticalAlign="center" className="mt-1">
                    <Flex className="flex-1 border rounded-xl h-11 border-denim-20 mr-2 text-center text-[15px] font-medium" align="center" verticalAlign="center">
                        <Text type="sm-medium" color="denim-50">00:00</Text>
                    </Flex>
                    <FlexRow align="space-between" verticalAlign="center">
                        <Flex className="flex-1 border rounded-tl-xl rounded-bl-xl h-11 w-12 border-denim-20 px-1 border-r-0" verticalAlign="center" align="center">
                            <Text type="sm-medium" color="denim-50">AM</Text>
                        </Flex>
                        <Flex className="flex-1 border rounded-tr-xl rounded-br-xl h-11 w-12 border-denim-20 px-1" verticalAlign="center" align="center">
                            <Text type="sm-medium" color="denim-50">PM</Text>
                        </Flex>
                    </FlexRow>
                </FlexRow>
            </Flex>
        </>
    }
    const renderInputs = () => {
        return <>
            <Flex className="my-5">
                <Text type="xs-medium" color="navy-100">Meal Out</Text>
                <FlexRow align="space-between" verticalAlign="center" className="mt-1">
                    <TimeInput label="Select Time" value={timeOut} onChange={setTimeOut}/>
                    <FlexRow align="space-between" verticalAlign="center" className="ml-2">
                        <TouchableOpacity className={`flex-1 border rounded-tl-xl rounded-bl-xl h-11 w-12 border-denim-20 px-1 border-r-0 ${selectedTimeOutType == 'am' ? 'bg-navy-light-100' : ''}`}
                                          onPress={() => setSelectedTimeOutType("am")}>
                            <Flex className="flex-1" verticalAlign="center" align="center">
                                <Text type="sm-medium" color={selectedTimeOutType == 'am' ? "white" : !isEmpty(selectedTimeOutType) ? "denim-50" : "coral-100"}>AM</Text>
                            </Flex>
                        </TouchableOpacity>
                        <TouchableOpacity className={`flex-1 border rounded-tr-xl rounded-br-xl h-11 w-12 border-denim-20 px-1 ${selectedTimeOutType == 'pm' ? 'bg-navy-light-100' : ''}`} onPress={() => setSelectedTimeOutType("pm")}>
                            <Flex className="flex-1" verticalAlign="center" align="center">
                                <Text type="sm-medium" color={selectedTimeOutType == 'pm' ? "white" : !isEmpty(selectedTimeOutType) ? "denim-50" : "coral-100"}>PM</Text>
                            </Flex>
                        </TouchableOpacity>
                    </FlexRow>
                </FlexRow>
            </Flex>

            <Flex className="my-5">
                <Text type="xs-medium" color="navy-100">Meal In</Text>
                <FlexRow align="space-between" verticalAlign="center" className="mt-1">
                    <TimeInput label="Select Time" value={timeIn} onChange={setTimeIn}/>
                    <FlexRow align="space-between" verticalAlign="center" className="ml-2">
                        <TouchableOpacity className={`flex-1 border rounded-tl-xl rounded-bl-xl h-11 w-12 border-denim-20 px-1 border-r-0 ${selectedTimeInType == 'am' ? 'bg-navy-light-100' : ''}`}
                                          onPress={() => setSelectedTimeInType("am")}>
                            <Flex className="flex-1" verticalAlign="center" align="center">
                                <Text type="sm-medium" color={selectedTimeInType == 'am' ? "white" : !isEmpty(selectedTimeInType) ? "denim-50" : "coral-100"}>AM</Text>
                            </Flex>
                        </TouchableOpacity>
                        <TouchableOpacity className={`flex-1 border rounded-tr-xl rounded-br-xl h-11 w-12 border-denim-20 px-1 ${selectedTimeInType == 'pm' ? 'bg-navy-light-100' : ''}`} onPress={() => setSelectedTimeInType("pm")}>
                            <Flex className="flex-1" verticalAlign="center" align="center">
                                <Text type="sm-medium" color={selectedTimeInType == 'pm' ? "white" : !isEmpty(selectedTimeInType) ? "denim-50" : "coral-100"}>PM</Text>
                            </Flex>
                        </TouchableOpacity>
                    </FlexRow>
                </FlexRow>
            </Flex></>
    }

    return <Flex>
        {renderContent()}
        <RBSheet
            ref={refRBSheet}
            animationType={"fade"}
            height={height < 500 ? height * 0.9 : 500}
            customStyles={{
                container: {
                    borderTopLeftRadius: 28,
                    borderTopRightRadius: 28,
                    width: getGlobal().deviceType == DeviceType.DESKTOP ? "500px" : "100%",
                    marginHorizontal: getGlobal().deviceType == DeviceType.DESKTOP ? "auto" : 0
                },
            }}
        >
            <Flex className="flex-1 p-8" verticalAlign="space-between">
                <Flex className="flex-grow gap-y-5">

                    <Text type="sm-semibold" color="navy-100">{label}</Text>

                    <FlexRow align="space-between" verticalAlign="center" className="my-5">
                        <TouchableOpacity className={`flex-1 border rounded-tl-xl rounded-bl-xl h-11 w-12 border-denim-20 px-1 border-r-0 ${selectedType == 'taken' ? 'bg-navy-light-100' : ''}`} onPress={() => setSelectedType("taken")}>
                            <Flex className="flex-1" verticalAlign="center" align="center">
                                <Text type="sm-medium" color={selectedType == 'taken' ? "white" : "denim-50"}>Meal Taken</Text>
                            </Flex>
                        </TouchableOpacity>
                        <TouchableOpacity className={`flex-1 border rounded-tr-xl rounded-br-xl h-11 w-12 border-denim-20 px-1 ${selectedType == 'skipped' ? 'bg-navy-light-100' : ''}`} onPress={() => setSelectedType("skipped")}>
                            <Flex className="flex-1" verticalAlign="center" align="center">
                                <Text type="sm-medium" color={selectedType == 'skipped' ? "white" : "denim-50"}>Meal Skipped</Text>
                            </Flex>
                        </TouchableOpacity>
                    </FlexRow>

                    {selectedType == "taken" && renderInputs()}
                    {selectedType == "skipped" && renderDisabledInputs()}

                </Flex>
                <Flex className="gap-y-2">
                    <SecondaryButton value="Cancel" onPress={() => refRBSheet.current.close()}/>
                    <Button
                        value="Save"
                        onPress={() => save()}
                        useFullColor={true}
                        loading={loading}
                        disabled={
                            (isEmpty(selectedTimeOutType) || isEmpty(timeOut) || isEmpty(selectedTimeInType) || isEmpty(timeIn)) && selectedType == "taken"
                        }
                    />
                </Flex>
            </Flex>
        </RBSheet>
    </Flex>
};