import React, {useEffect} from "react";
import {TouchableOpacity} from "react-native";

import FlexRow from "@components/Views/FlexRow";
import Flex from "@components/Views/Flex";
import Text from "@components/General/Text";

type TabsProps = {
    values: string[],
    defaultSelected: string | null,
    align?: string
    background?: string
    btnBackground?: string
    onSelected: (value: string) => void,
};
export default function ContentTabs({values, align = "between", background = "bg-navy-light-100", btnBackground = "bg-white", onSelected, defaultSelected}: TabsProps) {

    const [selected, setSelected] = React.useState(0);
    const btnColor = btnBackground == "bg-white" ? "navy-light-100" : "white";

    const updateSelected = (value: string, index: number) => {
        setSelected(index);
        onSelected(value.toLowerCase().replace(" ", "-"));
    };

    useEffect(() => {
        if (defaultSelected != null) {
            const indexDefaultSelected = values.findIndex(value => value.toLowerCase().replace(" ", "-") === defaultSelected);
            setSelected(indexDefaultSelected)
        }
    }, [])

    const rounded = (index: number) => {
        if (index == 0) return "rounded-l-lg";
        if (index == values.length - 1) return "rounded-r-lg";
        return "";
    };

    const border = (index: number) => {
        if (index == 0) return "";
        return "border-l border-denim-100/10";
    }

    return <FlexRow verticalAlign="center" align={align} className={`w-full ${background} h-12 px-5`}>
        {values.map((value, index) =>
            <TouchableOpacity className={`rounded-lg h-9 px-2 ${selected == index ? btnBackground : ""}`} onPress={() => updateSelected(value, index)} key={index}>
                <Flex verticalAlign="center" align="center" className="flex-1">
                    <Text type="sm-medium" color={selected == index ? btnColor : "white/40"} className="text-center">{value}</Text>
                </Flex>
            </TouchableOpacity>
        )}
    </FlexRow>
}