import React from "react";

import FlexRow from "@components/Views/FlexRow";
import Text from "@components/General/Text";
import Flex from "@components/Views/Flex";
import {transformTailwindToHex} from "@src/helpers";
import CheckRounded from "@assets/icons/check-rounded.svg";

type ContentTableProps = {
    showIcons?: boolean,
    values: string[][],
    className?: string,
};
export default function ContentList({showIcons = false, values, ...props}: ContentTableProps) {

    const renderRows = () => {
        return values.map((rowValues, rowIndex) => {
                    const border = rowIndex == values.length - 1 ? "" : "border-b-0.5 border-denim-20";
                    return <FlexRow align="between" className={`py-3 ${border}`} key={`row-${rowIndex}`}>
                        {rowValues.map((value, index) => {
                            if (index == 0 && showIcons) {
                                return <FlexRow className="gap-x-2">
                                    <Flex><CheckRounded width={18} height={18} fill={transformTailwindToHex("navy-light-100")}/></Flex>
                                    <Text type="sm-medium" key={index}>{value}</Text>
                                </FlexRow>
                            }

                            if (index == 0) {
                                return <Text type="sm-medium" key={index} color='denim-100'>{value}</Text>
                            }

                            return <Text type="xs-medium" key={index} color={index == 1 ? 'navy-light-100' : 'denim-100'}>{value}</Text>
                        })}
                    </FlexRow>
                }
        )

    }

    return <Flex className="w-full" {...props}>
        {renderRows()}
    </Flex>
}