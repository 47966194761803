import React, {useEffect, useState} from "react";
import TextInput from "@components/Forms/TextInput";
import CurrencyInputStyled from "@components/Forms/CurrencyInput";
import Text from "@components/General/Text";
import Flex from "@components/Views/Flex";
import RentalDates from "@pages/SignedIn/ProductionPayment/components/RentalDates";
import FlexRow from "@components/Views/FlexRow";
import {toTwoDecimal} from "@src/helpers";

export default function KitRental({data, onUpdateData, productionId}) {

    const [paymentAmount, setPaymentAmount] = useState<number | null>(null);
    const [rentalDates, setRentalDates] = useState<string[]>([]);
    const [paymentNotes, setPaymentNotes] = useState<string>("");

    useEffect(() => {
        if (data.amount !== undefined) {
            setPaymentAmount(data.amount);
        }

        if (data.note !== undefined) {
            setPaymentNotes(data.note);
        }

        if (data.extra !== undefined) {
            setRentalDates(data.extra.rental_dates);
        }
    }, []);

    useEffect(() => {
        onUpdateData({
            date: rentalDates.length > 0 ? rentalDates[0] : null,
            amount: (paymentAmount ?? 0)*rentalDates.length,
            note: paymentNotes,
            extra: {
                rental_dates: rentalDates,
                rate_per_day: paymentAmount
            }
        });

    }, [paymentAmount, rentalDates, paymentNotes]);


    return <Flex>
        <RentalDates label="Rental Dates" value={rentalDates} disabled={false} updateValue={setRentalDates} productionId={productionId}/>

        <Flex>
            <Flex className="absolute top-4 left-3"><Text type="xs-medium" color="denim-50">$</Text></Flex>
            <CurrencyInputStyled label="Rate (Per-Day)" value={paymentAmount} onChange={setPaymentAmount} paddingLeft="pl-6"/>
        </Flex>

        <TextInput label="List Items" value={paymentNotes} onChange={setPaymentNotes} bigText={true}/>

        <FlexRow verticalAlign="center" align="between" className="w-full">
            <Text type="sm-medium" color="denim-50" className="my-5">Total Payment (All Days):</Text>
            <Text type="sm-medium" color={rentalDates.length == 0 || paymentAmount == null ? "coral-100" : "navy-light-100"} className="my-5">${toTwoDecimal(rentalDates.length*(paymentAmount ?? 0))}</Text>
        </FlexRow>
    </Flex>;
}