import React, {useEffect, useState} from "react";
import TextInput from "@components/Forms/TextInput";

import Flex from "@components/Views/Flex";
import Button from "@components/Buttons/Button";
import {userLoginType} from "@interfaces/UserInterface";
import userApi from "@api/userApi";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {setGlobal} from "reactn";
import AddressInput from "@components/Forms/AddressInput";
import FloatingBottom from "@components/Views/FloatingBottom";

export default function PersonalInfo() {
    const [loading, setLoading] = useState<boolean>(false);

    const [name, setName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [homeAddress, setHomeAddress] = useState<object | string | null>(null);
    const [extra, setExtra] = useState<string>("");

    useEffect(() => {
        getUser();
    }, []);

    const getUser = async () => {
        setLoading(true);

        try {
            let response: userLoginType = await userApi.getAccount();

            setName(response.data.attributes.first_name);
            setLastName(response.data.attributes.last_name);
            setPhoneNumber(response.data.attributes.phone_number);
            setHomeAddress(response.data.attributes.address);
            setExtra(response.data.attributes.extra);

        } catch (e) {}

        setLoading(false);
    };


    const updateDetails = async () => {
        setLoading(true);

        try {
            let response: userLoginType = await userApi.putAccount({
                first_name: name,
                last_name: lastName,
                phone_number: phoneNumber,
                address: homeAddress,
                extra: extra,
            });

        } catch (e) {}

        setLoading(false);
    };

    const logout = async () => {

        try {
            await AsyncStorage.removeItem('access_token');
            await AsyncStorage.removeItem('user');
            await setGlobal({
                user: null,
                access_token: null
            });
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <Flex className="flex-1" verticalAlign="content-between">

            <Flex className="flex-1 bg-white" verticalAlign="between">
                <Flex className="flex-1 px-5 gap-y-2 pb-5 pt-5">
                    <TextInput label="First Name" onChange={setName} value={name}/>
                    <TextInput label="Last Name" onChange={setLastName} value={lastName}/>
                    <TextInput label="Phone Number" onChange={setPhoneNumber} value={phoneNumber}/>
                    <AddressInput label="test" value={homeAddress} onChange={setHomeAddress}/>
                    <TextInput label="Home Address (Line 2)" onChange={setExtra} value={extra}/>
                </Flex>


                <FloatingBottom>
                    <Button
                        useFullColor={true}
                        value="Save"
                        className="flex-grow"
                        onPress={() => updateDetails()}
                        disabled={name == "" || lastName == "" || phoneNumber == "" || homeAddress == null}
                    />
                </FloatingBottom>
            </Flex>
        </Flex>
    )
        ;
}
