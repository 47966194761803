import React, {useEffect, useState} from "react";
import TextInput from "@components/Forms/TextInput";

import Text from "@components/General/Text";
import Flex from "@components/Views/Flex";
import Button from "@components/Buttons/Button";
import Header from "@components/Views/Header";
import AddressInput from "@components/Forms/AddressInput";
import {signUpData} from "@pages/SignedOut/GettingStarted";
import {maskNumberInput} from "@src/helpers";

type GettingStartedStep2Props = {
    data: signUpData,
    updateData: (data: {}) => void
    loading: boolean
}

export default function GettingStartedStep2(props: GettingStartedStep2Props) {
    const [name, setName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [homeAddress, setHomeAddress] = useState<object | string | null>(null);
    const [extra, setExtra] = useState<string>("");
    const [ssn, setSSN] = useState<string>("");
    const [secureSsn, setSecureSSN] = useState<string>("");

    useEffect(() => {
        updateDataBasedOnProps();
    }, []);

    const updateDataBasedOnProps = () => {
        setName(props.data.first_name ?? "");
        setLastName(props.data.last_name ?? "");
        setPhoneNumber(props.data.phone_number ?? "");
        setHomeAddress(props.data.address ?? null);
        setExtra(props.data.extra ?? "");
        setSSN(props.data.ssn ?? "");
    }

    const goToNextStep = () => {
        props.updateData({
            first_name: name,
            last_name: lastName,
            phone_number: phoneNumber,
            address: homeAddress,
            extra: extra,
            ssn: ssn
        });
    }

    return (
        <Flex className="flex-1" verticalAlign="content-between">

            <Header title="Getting Started" showBack={false}/>

            <Flex className="px-5 gap-y-2 pb-5 bg-navy-light-100 pt-5">
                <Text type="sm-semibold" color="white">Setup Your FilmUp Account</Text>
                <Text type="xs-normal" className="opacity-70 pt-2" color="white">Confirm the email address and password that you would like to use to access your FilmUp account.</Text>
            </Flex>

            <Flex className="flex-1 bg-white" verticalAlign="between">

                <Flex className="flex-1 p-5 gap-y-2">
                    <TextInput label="First Name" onChange={setName} value={name}/>
                    <TextInput label="Last Name" onChange={setLastName} value={lastName}/>
                    <TextInput label="Phone Number" onChange={setPhoneNumber} value={phoneNumber}/>
                    <AddressInput label="test" value={homeAddress as string} onChange={setHomeAddress}/>
                    <TextInput label="Home Address (Line 2)" onChange={setExtra} value={extra} required={false}/>
                    <TextInput
                            label="SSN"
                            keyboardType='numeric'
                            onChange={(value) => {
                                let maskedValue = maskNumberInput(value, '999-99-9999', true, ssn);

                                setSSN(maskedValue.text)
                                setSecureSSN(maskedValue.secureText)
                            }}
                            value={secureSsn}
                    />
                </Flex>

                <Flex className="rounded-3xl px-5 py-3 bg-white shadow-lg gap-y-2 pb-5">
                    <Button
                        useFullColor={true}
                        value="Sign Up"
                        className="mt-2"
                        loading={props.loading}
                        onPress={() => goToNextStep()}
                        disabled={name == "" || lastName == "" || phoneNumber == "" || homeAddress == null || ssn == ""}
                    />
                </Flex>
            </Flex>
        </Flex>
    )
}
