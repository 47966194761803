import Text from "@components/General/Text";
import Flex from "@components/Views/Flex";
import React from "react";

type NoDataProps = {
    text: string,
};

export default function NoData({text}: NoDataProps) {
    return <Flex className="pt-5" verticalAlign="center" align="center">
        <Text type="sm-medium" color="denim-50">{text}</Text>
    </Flex>
}