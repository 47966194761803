import React, {useRef, useState} from "react";
import {Linking, ScrollView, TouchableOpacity,} from "react-native";

import Flex from "@components/Views/Flex";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import SubContentTabs from "@components/General/SubContentTabs";
import {productionType} from "@interfaces/ProductionInterface";
import Content from "@components/General/Content";
import FlexRow from "@components/Views/FlexRow";
import Text from "@components/General/Text";
import {formatDateDefaultYear} from "@src/helpers";
import ContentTable from "@components/General/ContentTable";
import {translatedKitFee, translatedRateAmount} from "@components/General/Rates";
import {navigationProps} from "@interfaces/RoutesInterface";
import {useNavigation} from "@react-navigation/native";
import RBSheet from "react-native-raw-bottom-sheet";
import {getGlobal} from "reactn";
import {DeviceType} from "expo-device";

type YourRoleTabProps = {
    production: productionType
}
export default function YourRoleTab({production}: YourRoleTabProps) {

    const [selectedTab, setSelectedTab] = useState<string>("overview");
    const [viewDetailsTitle, setViewDetailsTitle] = useState<string>('');
    const [viewDetailsContent, setViewDetailsContent] = useState<string>('');

    const navigation = useNavigation<navigationProps>();
    const insets = useSafeAreaInsets();
    const refRBSheet = useRef();

    return (
        <Flex className="flex-1">
            <SubContentTabs values={["Overview", "Documents"]} align="start" defaultSelected={selectedTab} onSelected={(value) => setSelectedTab(value)}/>
            <ScrollView
                className="flex-1 px-6 pt-5"
                style={{paddingBottom: insets.bottom}}>

                {selectedTab == "overview" &&
                    <>
                        <Flex className="flex-grow gap-y-1 pb-5 border-b-0.5 border-denim-20 mb-4 -mx-6 px-6">
                            <Text type="sm-bold" color="navy-light-100">{production.attributes.role}</Text>
                            <FlexRow verticalAlign="center" align="between">
                                <Text type="xs-medium" color="denim-50">Accepted On: {formatDateDefaultYear(production.attributes.accepted_at)}</Text>
                                <TouchableOpacity onPress={() => navigation.navigate("Offer", {offerId: production.attributes.offer_id})}>
                                    <Text type="xs-medium" color="navy-light-100">View Offer</Text>
                                </TouchableOpacity>
                            </FlexRow>
                        </Flex>

                        <ContentTable className="mb-7" rowAmount={1} values={
                            [
                                {title: "Working Days", value: `${production.attributes.total_shooting_days} Days`},
                                {title: "Rate", value: translatedRateAmount(production.attributes.rate)},
                                {title: "Kit Rental", value: translatedKitFee(production.attributes.rate)},
                                {title: "Employment Type", value: production.attributes.employment},
                            ]
                        }/>

                        {(production.attributes.policies.travel != undefined ||
                                production.attributes.policies.expense != undefined ||
                                production.attributes.policies.bonus != undefined) &&
                            <Content title="Crew Policies">
                                {production.attributes.policies.travel != undefined && <FlexRow verticalAlign="center" align="between" className="h-11 border-b-0.5 border-denim-20 -mt-3">
                                    <Text type="sm-medium" color="denim-100">Travel Compensation</Text>
                                    <TouchableOpacity onPress={() => {
                                        setViewDetailsTitle("Travel Compensation");
                                        setViewDetailsContent(production.attributes.policies.travel);
                                        refRBSheet.current.open();
                                    }}>
                                        <Text type="xs-medium" color="navy-light-100">View</Text>
                                    </TouchableOpacity>
                                </FlexRow>}

                                {production.attributes.policies.expense != undefined && <FlexRow verticalAlign="center" align="between" className="h-11 border-b-0.5 border-denim-20">
                                    <Text type="sm-medium" color="denim-100">Expense</Text>
                                    <TouchableOpacity onPress={() => {
                                        setViewDetailsTitle("Expense");
                                        setViewDetailsContent(production.attributes.policies.expense);
                                        refRBSheet.current.open();
                                    }}>
                                        <Text type="xs-medium" color="navy-light-100">View</Text>
                                    </TouchableOpacity>
                                </FlexRow>}

                                {production.attributes.policies.bonus != undefined && <FlexRow verticalAlign="center" align="between" className="h-11 border-b-0.5 border-denim-20">
                                    <Text type="sm-medium" color="denim-100">Bonus</Text>
                                    <TouchableOpacity onPress={() => {
                                        setViewDetailsTitle("Bonus");
                                        setViewDetailsContent(production.attributes.policies.bonus);
                                        refRBSheet.current.open();
                                    }}>
                                        <Text type="xs-medium" color="navy-light-100">View</Text>
                                    </TouchableOpacity>
                                </FlexRow>}
                            </Content>}
                    </>
                }


                {selectedTab == "documents" &&
                    <Content title="Your Documents">
                        {production.related.documents?.data.map((item, index) => (
                            <FlexRow className="py-4 border-b border-denim-10" verticalAlign="center">
                                <Flex className="flex-grow">
                                    <FlexRow align="between" verticalAlign="center">
                                        <Text type="sm-medium">{item.attributes.title}</Text>
                                    </FlexRow>
                                    <Text type="xs-medium" color="denim-50">Last Updated: {formatDateDefaultYear(item.attributes.submitted_at)}</Text>
                                </Flex>

                                {item.attributes.status == "pending" && <Flex verticalAlign="start" align="end">
                                    <Text type="xs-medium" color="coral-100">Pending Submission</Text>
                                </Flex>}

                                {item.attributes.status == "submitted" && <Flex verticalAlign="start" align="end">
                                    <Text type="xs-medium" color="orange-100">Pending Countersign</Text>
                                </Flex>}

                                {item.attributes.file !== null && item.attributes.status == "completed" && <Flex verticalAlign="start" align="end">
                                    <TouchableOpacity onPress={() => Linking.openURL(item.attributes.file)}>
                                        <Text type="xs-medium" color="navy-light-100">View</Text>
                                    </TouchableOpacity>
                                </Flex>}
                            </FlexRow>
                        ))}
                    </Content>
                }
            </ScrollView>

            <RBSheet
                ref={refRBSheet}
                animationType={"fade"}
                height={600}
                customStyles={{
                    container: {
                        borderTopLeftRadius: 28,
                        borderTopRightRadius: 28,
                        width: getGlobal().deviceType == DeviceType.DESKTOP ? "500px" : "100%",
                        marginHorizontal: getGlobal().deviceType == DeviceType.DESKTOP ? "auto" : 0
                    },
                }}
            >
                <Flex className="p-8 h-full gap-y-2" align="start">
                    <Text type="xs-semibold" color="navy-light-100">{viewDetailsTitle}</Text>
                    <Text type="xs-medium" color="denim-100/60">{viewDetailsContent}</Text>
                </Flex>
            </RBSheet>
        </Flex>
    );
}