import React, {useEffect, useState} from "react";
import {ActivityIndicator, Linking, ScrollView,} from "react-native";

import Flex from "@components/Views/Flex";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import SubContentTabs from "@components/General/SubContentTabs";
import {productionType} from "@interfaces/ProductionInterface";

import Text from "@components/General/Text";
import productionApi from "@api/productionApi";
import FlexRow from "@components/Views/FlexRow";
import {formatDateDefault, formatDateDefaultYear, translatedAmount} from "@src/helpers";
import {useFocusEffect} from "@react-navigation/native";
import SecondaryButton from "@components/Buttons/SecondaryButton";

type PaystubsTabProps = {
    production: productionType
}
export default function PaystubsTab({production}: PaystubsTabProps) {
    const insets = useSafeAreaInsets();
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedTab, setSelectedTab] = useState<string | null>(null);
    const [paystubList, setPaystubList] = useState<any>(null);
    const [paystub, setPaystub] = useState<any>(null);

    useFocusEffect(
        React.useCallback(() => {
            getPaystubList();
        }, [])
    );

    useEffect(() => {
        if (paystubList != null) {
            getPaystub(paystubList.find((paystubData: any) => {
                return (formatDateDefault(paystubData.attributes.start_date) + " to " + formatDateDefault(paystubData.attributes.end_date)).toLowerCase() == selectedTab.toLowerCase();
            }).id);
        }

    }, [selectedTab]);

    const getPaystubList = async () => {
        setLoading(true);

        let response = await productionApi.getPaystubList(`?filters[production]=${production.id}`);
        setPaystubList(response.data);

        if (response.data.length > 0) {
            setSelectedTab(formatDateDefault(response.data[0].attributes.start_date) + " to " + formatDateDefault(response.data[0].attributes.end_date));
        }

        setLoading(false);
    };

    const getPaystub = async (paystubId: string) => {
        setLoading(true);

        let response = await productionApi.getPaystub(paystubId);
        setPaystub(response.data);

        setLoading(false);
    };

    const renderPaystubList = () => {
        return <SubContentTabs values={paystubList.map((paystubData: any) => {
            return formatDateDefault(paystubData.attributes.start_date) + " to " + formatDateDefault(paystubData.attributes.end_date);
        })} align="start" defaultSelected={selectedTab} onSelected={(value) => setSelectedTab(value)}/>
    }

    const renderPaystub = () => {
        return (
            <Flex>
                <FlexRow className="flex-grow gap-y-1 pb-5 border-b-0.5 border-denim-20 mb-4 -mx-5 px-5 pt-5" verticalAlign="center" align="between">
                    <Text type="sm-semibold" color="navy-100">
                        {formatDateDefault(paystub.attributes.start_date)} - {formatDateDefaultYear(paystub.attributes.end_date)}
                    </Text>
                    {paystub.attributes.file != null && <Text type="xs-medium" color="emerald-100">Paid</Text>}
                </FlexRow>

                <Flex className="gap-y-1">
                    <FlexRow verticalAlign="center" align="between">
                        <Text type="xs-medium" color="denim-50">Net Pay (This Period)</Text>
                        <Text type="xs-medium" color="denim-50">Hourly Rate</Text>
                    </FlexRow>

                    <FlexRow verticalAlign="center" align="between">
                        <Text type="sm-medium" color="emerald-100">${((paystub.attributes.period.net_pay * 100) / 100).toFixed(2)}</Text>
                        <Text type="sm-medium" color="denim-100">{translatedAmount(paystub.attributes.period.hourly_rate)}</Text>
                    </FlexRow>
                </Flex>

                <Flex>
                    {paystub.attributes.period.wages.length > 0 &&
                        <Flex>
                            <FlexRow className="mt-5 border-b-0.5 border-denim-20 h-7" verticalAlign="center" align="between">
                                <Text type="xs-medium" color="navy-100" className="flex-1">Wages</Text>
                                <FlexRow className="flex-1" align="end">
                                    <Text type="xs-medium" color="denim-50">Hours</Text>
                                </FlexRow>
                                <FlexRow className="flex-1" align="end">
                                    <Text type="xs-medium" color="denim-50">Earnings</Text>
                                </FlexRow>
                            </FlexRow>

                            {paystub.attributes.period.wages.map((value, index) => {
                                return <FlexRow className="h-6" verticalAlign="center" align="between">
                                    <Text type="xs-medium" color="denim-50" className="flex-1">{value.label}</Text>
                                    <FlexRow className="flex-1" align="end">
                                        <Text type="xs-medium" color="denim-100">{value.hours}</Text>
                                    </FlexRow>
                                    <FlexRow className="flex-1" align="end">
                                        <Text type="xs-medium" color="denim-100">${((value.amount * 100) / 100).toFixed(2)}</Text>
                                    </FlexRow>
                                </FlexRow>
                            })}

                            <FlexRow className="h-6" verticalAlign="center" align="between">
                                <Text type="xs-medium" color="navy-100" className="flex-1">Total</Text>
                                <FlexRow className="flex-1" align="end">
                                    <Text type="xs-medium" color="navy-100">{
                                        paystub.attributes.period.wages.reduce((n, {hours}) => n + hours, 0)
                                    }</Text>
                                </FlexRow>
                                <FlexRow className="flex-1" align="end">
                                    <Text type="xs-medium" color="navy-100">${
                                        ((paystub.attributes.period.wages.reduce((n, {amount}) => n + amount, 0) * 100) / 100).toFixed(2)
                                    }</Text>
                                </FlexRow>
                            </FlexRow>
                        </Flex>
                    }

                    {paystub.attributes.period.other_taxable.length > 0 &&
                        <Flex>
                            <FlexRow className="mt-5 border-b-0.5 border-denim-20 h-7" verticalAlign="center" align="between">
                                <Text type="xs-medium" color="navy-light-100">Other Taxable</Text>
                                <Text type="xs-medium" color="denim-50">Earnings</Text>
                            </FlexRow>

                            {paystub.attributes.period.other_taxable.map((value, index) => {
                                return <FlexRow className="h-6" verticalAlign="center" align="between">
                                    <Text type="xs-medium" color="denim-50">{value.label}</Text>
                                    <Text type="xs-medium" color="denim-100">${((value.amount * 100) / 100).toFixed(2)}</Text>
                                </FlexRow>
                            })}

                            <FlexRow className="h-6" verticalAlign="center" align="between">
                                <Text type="xs-medium" color="navy-light-100">Total</Text>
                                <Text type="xs-medium" color="navy-light-100">${
                                    ((paystub.attributes.period.other_taxable.reduce((n, {amount}) => n + amount, 0) * 100) / 100).toFixed(2)
                                }</Text>
                            </FlexRow>
                        </Flex>
                    }

                    {paystub.attributes.period.withholdings.length > 0 && production.attributes.employment != "contractor" &&
                        <Flex>
                            <FlexRow className="mt-5 border-b-0.5 border-denim-20 h-7" verticalAlign="center" align="between">
                                <Text type="xs-medium" color="coral-100">Withholdings</Text>
                                <Text type="xs-medium" color="denim-50">Earnings</Text>
                            </FlexRow>

                            {paystub.attributes.period.withholdings.map((value, index) => {
                                return <FlexRow className="h-6" verticalAlign="center" align="between">
                                    <Text type="xs-medium" color="denim-50">{value.label}</Text>
                                    <Text type="xs-medium" color="denim-100">${((value.amount * 100) / 100).toFixed(2)}</Text>
                                </FlexRow>
                            })}

                            <FlexRow className="h-6" verticalAlign="center" align="between">
                                <Text type="xs-medium" color="coral-100">Total</Text>
                                <Text type="xs-medium" color="coral-100">${
                                    ((paystub.attributes.period.withholdings.reduce((n, {amount}) => n + amount, 0) * 100) / 100).toFixed(2)
                                }</Text>
                            </FlexRow>
                        </Flex>
                    }

                </Flex>

                <SecondaryButton value={"Download Paystub"} className="mt-5" onPress={() => Linking.openURL(paystub.attributes.file)}/>
                <SecondaryButton value={"Bank Details"} className="mt-2" onPress={() => null} disabled={true}/>

            </Flex>
        )
    }

    if (loading) {
        return <Flex className="flex-1"><ActivityIndicator className="mt-5"/></Flex>
    }

    if (paystubList == null || paystubList.length == 0 || paystub == null) {
        return <Flex className="flex-1 pt-5" align="center">
                <Text type="sm-semibold" color="denim-50">
                    No data to display
                </Text>
        </Flex>
    }

    return (
        <Flex className="flex-1">
            {renderPaystubList()}
            <ScrollView
                className="flex-1 px-5"
                style={{paddingBottom: insets.bottom == 0 ? 20 : insets.bottom}}>

                {renderPaystub()}

            </ScrollView>
        </Flex>
    );
}