import React, {useState} from "react";
import {ScrollView, TouchableOpacity,} from "react-native";

import Flex from "@components/Views/Flex";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import SubContentTabs from "@components/General/SubContentTabs";
import {productionType} from "@interfaces/ProductionInterface";
import FlexRow from "@components/Views/FlexRow";
import {formatDateLongDayMonth} from "@src/helpers";
import Text from "@components/General/Text";
import Content from "@components/General/Content";
import ContentTable from "@components/General/ContentTable";

type ProductionTabProps = {
    production: productionType
}
export default function ProductionTab({production}: ProductionTabProps) {
    const insets = useSafeAreaInsets();
    const [selectedTab, setSelectedTab] = useState<string>("schedule");

    return (
        <Flex className="flex-1">
            <SubContentTabs values={["Schedule", "Contact", "About"]} align="start" defaultSelected={selectedTab} onSelected={(value) => setSelectedTab(value)}/>
            <ScrollView
                className="flex-1 px-6 pt-5"
                style={{paddingBottom: insets.bottom == 0 ? 20 : insets.bottom}}>

                {selectedTab == "schedule" &&
                    <Flex className="-mt-5">
                        {production.related.shoots.data.map((item, index) => (
                            <TouchableOpacity onPress={() => null} className="w-full">
                                <FlexRow verticalAlign="start" align="start" className="py-4 border-b border-denim-10" key={index}>
                                    <Flex verticalAlign="center" className="flex-1">
                                        <Text type="sm-medium" color="denim-100">
                                            {formatDateLongDayMonth(item.attributes.date)}
                                        </Text>
                                        <Text type="xs-medium" color="denim-50">{item.attributes.location ?? "-"}</Text>
                                    </Flex>

                                    <Flex verticalAlign="start" align="end" className="mx-4">
                                        <Text type="xs-medium" color="navy-light-100">
                                            Day {item.attributes.current_day} of {item.attributes.total_days}
                                        </Text>
                                    </Flex>
                                </FlexRow>
                            </TouchableOpacity>))}
                    </Flex>
                }

                {selectedTab == "contact" &&
                    <>
                        <Content title="Your Production Contact">
                            <ContentTable rowAmount={1} values={
                                [
                                    {title: "Name", value: production.attributes.contact.name},
                                    {title: "Title", value: production.attributes.contact.title},
                                    {title: "Email", value: production.attributes.contact.email},
                                    {title: "Phone", value: production.attributes.contact.phone_number}
                                ]
                            }/>
                        </Content>

                        <Content title="Production Company">
                            <ContentTable rowAmount={1} values={
                                [
                                    {title: "Company Name", value: production.related.company.data.attributes.name},
                                    {title: "Company Address", value: production.attributes.address},
                                ]
                            }/>
                        </Content>
                    </>
                }

                {selectedTab == "about" &&
                    <>
                        <Content title="This Production">
                            <ContentTable rowAmount={1} values={
                                [
                                    {title: "Type", value: production.attributes.type},
                                    {title: "Synopsis", value: production.attributes.synopsis},
                                ]
                            }/>
                        </Content>

                        <Content title="Locations & Dates">
                            <ContentTable rowAmount={1} values={
                                production.related.shoots.data.map((item) => {
                                    return {title: item.attributes.location, value: formatDateLongDayMonth(item.attributes.date)};
                                })
                            }/>
                        </Content>
                    </>
                }

            </ScrollView>
        </Flex>
    );
}