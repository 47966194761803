import {Dimensions, LogBox, Platform, StatusBar, View} from "react-native";
import "react-native-gesture-handler";
import AsyncStorage from "@react-native-async-storage/async-storage";
import FlashMessage from 'react-native-flash-message';
import * as Linking from 'expo-linking';

import Routes from "./src/routes";
import {setGlobal, useEffect} from "reactn";

import * as Device from 'expo-device';
import {DeviceType} from 'expo-device';

import "./styles";
import * as serviceWorkerRegistration from "./src/serviceWorkerRegistration";
import {useState} from "react";
import PWAPrompt from 'react-ios-pwa-prompt'

const {width} = Dimensions.get('window');


if(__DEV__) {
    import('./reactotron');
    LogBox.ignoreAllLogs();
}
const setInitialGlobalState = async () => {
    const asyncToken = await AsyncStorage.getItem("access_token");
    const asyncUser = await AsyncStorage.getItem("user") ?? "{}";

    setGlobal({
        access_token: asyncToken,
        user: JSON.parse(asyncUser),
        change_app_background: false,
    });
};

export default function App() {
    let [displayDeeplinkModal, setDisplayDeeplinkModal] = useState(true);
    let [linkURL, setLinkURL] = useState<string|null>('');
    let [deviceType, setDeviceType] = useState<DeviceType|null>(null);

    useEffect(() => {
        setInitialGlobalState();
        getDeviceType();
        renderSmartlookContent();
    }, []);

    const getDeviceType = async () => {
        let deviceTypeData = await Device.getDeviceTypeAsync();
        setDeviceType(deviceTypeData)
        setGlobal({deviceType: deviceTypeData})
    }

    Linking.getInitialURL().then((url) => {
        if (url) {
            const {path} = Linking.parse(url);
            setLinkURL(path)
        }
    }).catch(err => console.error('An error occurred', err));

    const renderSmartlookContent = () => {
        // if (Platform.OS === 'web') {
        //     const script = document.createElement('script');
        //     script.setAttribute('type', 'text/javascript');
        //
        //     script.text = `
        //         var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
        //         var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
        //         c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
        //         })(document);
        //         smartlook('init', 'e458e7793a13bb9cb238197b25a53ef3f8acbf41', { region: 'eu' });
        //     `;
        //
        //     document.head.appendChild(script);
        // }
    }

    if (Platform.OS === 'web' && deviceType == DeviceType.DESKTOP && displayDeeplinkModal) {
        return (<View className="flex-1 bg-[#222436]">
            <View className="flex-1 bg-bluegray w-[500px] mx-auto shadow-lg">
                <StatusBar hidden={true}/>
                <Routes/>
                <FlashMessage position="top"/>
            </View>
        </View>)
    }

    return (
        <View className="flex-1 bg-white">
            <PWAPrompt
                promptOnVisit={1}
                timesToShow={3}
                copyClosePrompt="Close"
                permanentlyHideOnDismiss={false}
                copyTitle="Install App"
                copyBody="Add the app to your home screen to use it in the future. You'll get better performance and a better experience."
            />
            <StatusBar style="light" />
            <Routes />
            <FlashMessage position="top" />
        </View>
    );
}

serviceWorkerRegistration.register();