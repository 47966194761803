import {ActivityIndicator, StyleProp, TouchableOpacity} from "react-native";
import React from "react";
import Text from "@components/General/Text";

type ButtonProps = {
    value: string,
    color?: string,
    radius?: string,
    className?: string,
    onPress?: () => void,
    type?: "md" | "lg",
    loading?: boolean,
    disabled?: boolean,
};

export default function SecondaryButton({value, color = "denim-100",  type = "lg", loading = false, disabled = false, ...props}: ButtonProps) {

    const baseColor = color.split("-")[0]; // denim
    const height = type === "lg" ? "h-10" : "h-8";
    const textType = type === "lg" ? "sm-bold" : "sm-semibold";
    const border = `border border-${baseColor}-20`;
    const opacity = loading || disabled ? 'opacity-50' : '';

    return (
        <TouchableOpacity className={`rounded-${type} px-5 ${height} ${border} ${opacity} justify-center items-center`} {...props} disabled={loading || disabled}>
            {loading && <ActivityIndicator/>}
            {!loading && <Text type={textType} color={color}>{value}</Text>}
        </TouchableOpacity>
    );
}