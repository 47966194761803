import {TextInput as RNTextInput} from "react-native";
import React from "react";
import {transformTailwindToHex} from "@src/helpers";
import Flex from "@components/Views/Flex";
import Text from "@components/General/Text";

import CheckCircle from "@assets/icons/check-circle.svg";
import AlertCircle from "@assets/icons/alert-circle.svg";

type FormField = {
    label: string,
    value: string,
    password?: boolean,
    bigText?: boolean,
    required?: boolean,
    onChange: (value: string) => void,
    keyboardType?: string
    paddingLeft?: string
};

export default function TextInput({password = false, bigText = false, required = true, keyboardType = "text", ...props}: FormField) {
    return (
        <Flex className="w-full">
            {props.value != "" && props.value != null &&
                <Flex style={{paddingHorizontal: 5, marginBottom: -6, marginLeft: 12, zIndex: 1, backgroundColor: "#FFF", marginTop: -6, width: "fit-content"}}>
                    <Text style={{fontSize: 10, width: "fit-content"}} color="denim-50">{props.label}</Text>
                </Flex>
            }

            <RNTextInput
                placeholder={props.label} className={`w-full p-3 ${props.paddingLeft ? props.paddingLeft : ""} ${bigText ? "h-36" : "h-12"} rounded-xl text-sm mb-4 border border-denim-20`}
                placeholderTextColor={transformTailwindToHex("denim-50")}
                autoCapitalize="none"
                secureTextEntry={password}
                value={props.value}
                onChangeText={(text) => props.onChange(text)}
                numberOfLines={bigText ? 5 : 1}
                multiline={bigText}
                keyboardType={keyboardType}
            />

            {props.value != "" && props.value != null && <Flex align="end" className="absolute top-4 right-4">
                <CheckCircle width={16} height={16} fill={transformTailwindToHex("navy-light-100")}/>
            </Flex>}

            {(props.value == "" || props.value == null) && required && <Flex align="end" className="absolute top-4 right-4">
                <AlertCircle width={16} height={16} fill={transformTailwindToHex("coral-100")}/>
            </Flex>}
        </Flex>
    );
}