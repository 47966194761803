import React from "react";
import {capitalizeFirstLetter} from "@helpers/index";
import Text from "@components/General/Text";

type TagProps = {
    type?: string
};

export default function Tag({type = "upcoming"}: TagProps) {

    const color = () => {
        switch (type) {
            case "rejected":
            case "incomplete":
            case "not signed":
            case "missing":
            case "not submitted":
                return "coral-100";
            case "wrapped":
            case "accepted":
            case "submitted":
                return "emerald-100";
            case "active":
            case "uploaded":
            case "signed":
                return "navy-light-100";
            default:
                return "denim-100/30";
        }
    }

    return <Text type="xs-bold" color={color()}>{capitalizeFirstLetter(type)}</Text>
}