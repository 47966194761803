import React from "react";
import {View} from "react-native";

type FlexRowProps = {
    align?: string,
    verticalAlign?: string,
    className?: string,
    children: React.ReactNode
};

export default function FlexRow(props: FlexRowProps) {
    return <View className={`flex-row items-${props.verticalAlign} justify-${props.align}`} {...props}>
        {props.children}
    </View>;
}