import React, {useEffect, useState} from "react";
import {userLoginType} from "@interfaces/UserInterface";
import userApi from "@api/userApi";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {setGlobal} from "reactn";
import GettingStartedStep1 from "@pages/SignedOut/GettingStarted/components/GettingStartedStep1";
import GettingStartedStep2 from "@pages/SignedOut/GettingStarted/components/GettingStartedStep2";

export type signUpData = {
    offer_id: string,
    email: string,
    first_name: string,
    last_name: string,
    phone_number: string,
    password: string,
    confirm_password: string,
    address: object | string | null,
    extra: string,
    ssn: string
}

export default function GettingStarted() {
    const [loading, setLoading] = useState<boolean>(false);
    const [step, setStep] = useState(0);

    const [signupData, setSignupData] = useState<signUpData>({} as signUpData);

    const [productionId, setProductionId] = useState<string | null>(null);
    const [productionName, setProductionName] = useState<string | null>(null);
    const [employmentType, setEmploymentType] = useState("w2");
    const [requireI9, setRequireI9] = useState<boolean>(false);

    useEffect(() => {
        getSignupDataFromLocalStorage();
    }, []);

    const getSignupDataFromLocalStorage = async () => {
        try {
            let data: signUpData = {} as signUpData;

            let signupDataLocalStorage = await AsyncStorage.getItem('signupData');
            if (signupDataLocalStorage) {
                data = JSON.parse(signupDataLocalStorage);
            }

            let signupOfferId = await AsyncStorage.getItem('signupOfferId');
            if (signupOfferId) {
                data.offer_id = signupOfferId;
            }

            let signupProductionId = await AsyncStorage.getItem('signupProductionId');
            if (signupProductionId) {
                setProductionId(signupProductionId);
            }

            let signupProductionEmployment = await AsyncStorage.getItem('signupProductionEmployment');
            if (signupProductionEmployment) {
                setEmploymentType(signupProductionEmployment);
            }

            // let signupProductionHasI9 = await AsyncStorage.getItem('signupProductionHasI9');
            // if (signupProductionHasI9) {
            //     setEmploymentType(signupProductionHasI9);
            // }

            let signupProductionName = await AsyncStorage.getItem('signupProductionName');
            if (signupProductionName) {
                setProductionName(signupProductionName);
            }

            setSignupData(data);
            setStep(1);
        } catch (e) {
            console.log(e);
        }
    }

    const singup = async (data: signUpData) => {
        setLoading(true);

        try {
            let response: userLoginType = await userApi.signup(data);

            await AsyncStorage.removeItem('signupData');
            await AsyncStorage.removeItem('signupOfferId');
            await AsyncStorage.removeItem('signupProductionId');
            await AsyncStorage.removeItem('signupProductionName');
            await AsyncStorage.removeItem('signupProductionEmployment');
            await AsyncStorage.removeItem('signupProductionHasI9');
            await AsyncStorage.setItem('initialOnboarding', JSON.stringify({
                page: "OfferExtraCrewData",
                params: {
                    offerId: signupData.offer_id,
                    productionId: productionId,
                    productionName: productionName,
                    employment: employmentType,
                    require_i9: requireI9
                }
            }));

            await AsyncStorage.setItem('access_token', response.meta.access_token);
            await AsyncStorage.setItem('user', JSON.stringify(response.data.attributes));

            await setGlobal({
                user: response.data.attributes,
                access_token: response.meta.access_token
            });

        } catch (e) {}

        setLoading(false);
        setStep(3);
    };

    return (
        <>
            {step == 1 && <GettingStartedStep1
                data={signupData}
                updateData={(data: {}) => {
                    setSignupData({
                        ...signupData,
                        ...data
                    });

                    setStep(2);
                }}
            />}

            {step == 2 && <GettingStartedStep2
                data={signupData}
                updateData={(data: {}) => {
                    singup({
                        ...signupData,
                        ...data
                    });
                }}
                loading={loading}
            />}
        </>
    )
}
