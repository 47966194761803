import React from "react";
import Flex from "@components/Views/Flex";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {View} from "react-native";
import {DeviceType} from "expo-device";
import {getGlobal} from "reactn";

type ContentProps = {
    className?: string,
    fixBottomPadding?: boolean,
    children: React.ReactNode
};

export default function FloatingBottom({fixBottomPadding = false, ...props}: ContentProps) {
    const insets = useSafeAreaInsets();

    return (
        <Flex>
            <Flex
                className={`rounded-tl-3xl rounded-tr-3xl p-5 bg-white mt-5 fixed bottom-0 z-10 ${fixBottomPadding ? "-mb-5" : ""}`}
                style={{
                    paddingBottom: insets.bottom + 20,
                    shadowColor: "#131E3C",
                    shadowOffset: {
                        width: 0,
                        height: 0,
                    },
                    shadowOpacity: 0.4,
                    shadowRadius: 34,
                    elevation: 10,
                    width: getGlobal().deviceType == DeviceType.DESKTOP ? "500px" : "100%",
                }}
            >
                {props.children}
            </Flex>
            <View className="h-32" />
        </Flex>
    );
}