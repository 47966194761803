import React, {useRef, useState} from 'react';
import {Dimensions, TouchableOpacity} from 'react-native';
import Flex from "@components/Views/Flex";

import Text from "@components/General/Text";
import FlexRow from "@components/Views/FlexRow";
import RBSheet from "react-native-raw-bottom-sheet";
import Button from "@components/Buttons/Button";

import CheckCircle from "@assets/icons/check-circle.svg";
import AlertCircle from "@assets/icons/alert-circle.svg";
import LockCircle from "@assets/icons/lock-circle.svg";

import productionApi from "@api/productionApi";
import {formatTimecardtime, isEmpty, transformTailwindToHex, validateTime} from "@src/helpers";
import {ShootType} from "@interfaces/ProductionInterface";
import {showMessage} from "react-native-flash-message";
import SecondaryButton from "@components/Buttons/SecondaryButton";
import {getGlobal} from "reactn";
import {DeviceType} from "expo-device";
import TimeInput from "@components/Forms/TimeInput";

const {height} = Dimensions.get('window');
const hours = Array.from(Array(12).keys()).map((i) => i + 1);
const minutes = Array.from(Array(60).keys()).map((i) => i);

interface TimeCardRowProps {
    label: string,
    value: string | null
    disabled: boolean
    locked: boolean,
    shootId: string,
    type: string,
    completedBy: null | string,
    updateTimeCard: (shoot: ShootType) => void;
}

export default function TimeCardRow({
                                        label,
                                        value,
                                        disabled,
                                        locked,
                                        shootId,
                                        type,
                                        completedBy,
                                        updateTimeCard
                                    }: TimeCardRowProps) {

    const [time, setTime] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedTimeType, setSelectedTimeType] = useState<string>("");
    const refRBSheet = useRef();

    const save = async () => {

        const timecardTime = validateTime(time, selectedTimeType);

        if (timecardTime == null) {
            showMessage({
                message: "Invalid Time",
                description: "Please enter a valid time",
                type: "danger",
            });

            return;
        }

        setLoading(true);

        let response: { data: ShootType } = await productionApi.postShootTimecard(shootId, {[type]: timecardTime});

        if (response?.data != null) {
            updateTimeCard(response.data);
        }

        setLoading(false);
        refRBSheet.current.close()
    }

    const setTimeType = (type: string) => {
        setSelectedTimeType(type)
    }

    const textColor = () => {
        if (completedBy != null && completedBy != "Self") {
            return "grape-100"
        }

        if (disabled || (value != null && value != "")) {
            return "denim-100"
        }

        return "coral-100"
    }

    const checkifTimeIsMoreThen12 = () => {
        if (time != null) {
            let timeArr = time.split(":");

            if (parseInt(timeArr[0]) > 12) {
                return true
            }
        }

        return false
    }

    const renderContent = () => {

        if ((value != null && value != "") || locked) {
            return <TouchableOpacity onPress={() => {

                if (!isEmpty(value)) {
                    let tmpTime = formatTimecardtime(value).split(" ");

                    setTime(tmpTime[0]);

                    if (tmpTime[1] == "a") {
                        setSelectedTimeType("am");
                    }

                    if (tmpTime[1] == "p") {
                        setSelectedTimeType("pm");
                    }
                }

                refRBSheet.current.open()
            }} disabled={locked || (completedBy != null && completedBy != "Self")}>
                <FlexRow verticalAlign="center" align="between" className="h-12 border-b border-denim-10">
                    <Flex className="w-24" align="start">
                        <Text type="sm-medium" color="denim-50">{label}</Text>
                    </Flex>
                    <Flex className="flex-1" align="start">
                        <Text type="sm-medium" color={textColor()}>{formatTimecardtime(value)}</Text>
                    </Flex>
                    {(completedBy != null && completedBy != "Self") &&
                        <Flex align="end">
                            <LockCircle width={16} height={16} fill={transformTailwindToHex("grape-100")}/>
                        </Flex>
                    }

                    {locked &&
                        <Flex align="end">
                            <LockCircle width={16} height={16} fill={transformTailwindToHex("emerald-100")}/>
                        </Flex>
                    }

                    {!locked && completedBy != null && completedBy == "Self" &&
                        <Flex align="end">
                            <CheckCircle width={16} height={16} fill={transformTailwindToHex("navy-light-100")}/>
                        </Flex>
                    }
                </FlexRow>
            </TouchableOpacity>
        }

        return (
            <TouchableOpacity onPress={() => {
                setTime(null);
                setSelectedTimeType("");

                refRBSheet.current.open()
            }} disabled={disabled}>
                <FlexRow verticalAlign="center" align="between" className="h-12 border-b border-denim-10">
                    <Text type="sm-medium" color={textColor()}>{label}</Text>

                    <Flex align="end">
                        <AlertCircle width={16} height={16} fill={transformTailwindToHex("coral-100")}/>
                    </Flex>
                </FlexRow>
            </TouchableOpacity>
        );
    }

    return <Flex>
        {renderContent()}
        <RBSheet
            ref={refRBSheet}
            animationType={"fade"}
            height={height < 310 ? height * 0.9 : 310}
            customStyles={{
                container: {
                    borderTopLeftRadius: 28,
                    borderTopRightRadius: 28,
                    width: getGlobal().deviceType == DeviceType.DESKTOP ? "500px" : "100%",
                    marginHorizontal: getGlobal().deviceType == DeviceType.DESKTOP ? "auto" : 0
                },
            }}
        >
            <Flex className="flex-1 p-8" verticalAlign="space-between">
                <Flex className="flex-grow">
                    <Text type="sm-semibold" color="navy-100">{label}</Text>

                    <FlexRow align="space-between" verticalAlign="center" className="my-5">
                        <TimeInput label="Select Time" value={time} onChange={setTime}/>

                        <FlexRow verticalAlign="center" className="ml-2">
                            <TouchableOpacity
                                className={`flex-1 border rounded-tl-xl rounded-bl-xl h-11 w-12 border-denim-20 px-1 border-r-0 ${selectedTimeType == 'am' ? 'bg-navy-light-100 ' : ''} ${checkifTimeIsMoreThen12() ? 'opacity-50' : ''}`}
                                onPress={() => setTimeType("am")}
                                disabled={checkifTimeIsMoreThen12()}
                            >
                                <Flex className="flex-1" verticalAlign="center" align="center">
                                    <Text type="sm-medium"
                                          color={selectedTimeType == 'am' ? "white" : !isEmpty(selectedTimeType) ? "denim-50" : "coral-100"}>AM</Text>
                                </Flex>
                            </TouchableOpacity>
                            <TouchableOpacity
                                className={`flex-1 border rounded-tr-xl rounded-br-xl h-11 w-12 border-denim-20 px-1 ${selectedTimeType == 'pm' ? 'bg-navy-light-100' : ''}`}
                                onPress={() => setTimeType("pm")}>
                                <Flex className="flex-1" verticalAlign="center" align="center">
                                    <Text type="sm-medium"
                                          color={selectedTimeType == 'pm' ? "white" : !isEmpty(selectedTimeType) ? "denim-50" : "coral-100"}>PM</Text>
                                </Flex>
                            </TouchableOpacity>
                        </FlexRow>
                    </FlexRow>

                </Flex>
                <Flex className="gap-y-2">
                    <SecondaryButton value="Cancel" onPress={() => refRBSheet.current.close()}/>
                    <Button value="Save" onPress={() => save()} useFullColor={true} loading={loading}
                            disabled={isEmpty(selectedTimeType) || isEmpty(time) || time == ":"}/>
                </Flex>
            </Flex>
        </RBSheet>
    </Flex>
};