import axios from "axios";
import AsyncStorage from '@react-native-async-storage/async-storage';
import {showMessage} from 'react-native-flash-message';
import {setGlobal} from "reactn";

interface Response {
    data?: object;
    status?: number;
}
interface Error {
    status?: number;
    response?: Response;
}
const api = {

    get(url: string, miragejs: boolean = false) {
        return this.request("GET", this.url(url, miragejs));
    },

    post(url: string, data: object, miragejs: boolean = false) {
        return this.request("POST", this.url(url, miragejs), data);
    },

    put(url: string, data: object, miragejs: boolean = false) {
        return this.request("PUT", this.url(url, miragejs), data);
    },

    delete(url: string, miragejs: boolean = false) {
        return this.request("DELETE", this.url(url, miragejs));
    },

    cancelSource() {
        return axios.CancelToken.source();
    },

    url(endpoint: string, miragejs: boolean = false) {

        const apiUrl = process.env.APP_API_URL;

        if (miragejs) {
            return "miragejs/api/crew" + endpoint;
        }

        return apiUrl + endpoint;
    },

    async treatResponse(response: any) {
        let responseJson;

        switch (response.status) {
            case 200:
            case 201:
                responseJson = await response.json();
                return responseJson;

            case 422:
                console.log(response);

                responseJson = await response.json();

                showMessage({
                    message: "Oops!",
                    description: responseJson.message,
                    type: 'danger',
                });

                throw new Error("Error");

            case 500:
                console.log(response);

                showMessage({
                    message: "Oops",
                    description: "Something Went Wrong...",
                    type: 'danger',
                });

                throw new Error("Error");

            default:
                console.log(response);

                responseJson = await response.json();

                showMessage({
                    message: "Oops!",
                    description: responseJson.message,
                    type: 'danger',
                });

                if (response.status == 401 && responseJson.message == "Unauthenticated.") {
                    this.logoutUser();
                }
        }
    },

    async request(method: string, url: string, data = {}) {
        const access_token = await AsyncStorage.getItem('access_token');

        let params: any = {
            method: method,
            cache: 'no-cache',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + access_token
            },
        };

        if (method !== 'GET' && method !== 'HEAD') {
            params.body = JSON.stringify(data);
        }

        let response = await fetch(url, params);
        return await this.treatResponse(response);
    },

    async postFormData(url: string, data: FormData) {

        const access_token = await AsyncStorage.getItem('access_token');

        let params: any = {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + access_token
            },
        };

        params.body = data;

        let response = await fetch(this.url(url, false), params);
        return await this.treatResponse(response);
    },

    async logoutUser() {

        await AsyncStorage.removeItem("access_token");
        await AsyncStorage.removeItem("user");

        setGlobal({
            access_token: null,
            user: {},
            change_app_background: false,
        });
    }
};

export default api;
