import React, {useState} from "react";
import {Platform, TouchableOpacity} from "react-native";

import {useSafeAreaInsets} from "react-native-safe-area-context";
import Text from "@components/General/Text";
import ArrowLeft from "@assets/icons/arrow-left.svg";
import * as Device from "expo-device";
import {DeviceType} from "expo-device";
import {useEffect} from "reactn";
import Flex from "@components/Views/Flex";
import {CommonActions, useNavigation} from "@react-navigation/native";

type HeaderProps = {
    title: string,
    color?: string,
    showBack?: boolean,
    onPressBack?: () => void,
    className?: string
    background?: string
}

export default function Header({color = "white", background = "bg-navy-light-100", showBack = true, onPressBack, ...props}: HeaderProps) {

    const insets = useSafeAreaInsets();
    const navigation = useNavigation();
    let [deviceType, setDeviceType] = useState<DeviceType | null>(null);

    useEffect(() => {
        getDeviceType();
    }, []);

    const getDeviceType = async () => {
        let tmp = await Device.getDeviceTypeAsync();
        setDeviceType(tmp)
    }

    const renderBackFunction = () => {
        if (showBack) {

            if (onPressBack != null) {
                return <Flex className="h-12 absolute left-5" verticalAlign="center" align="center" style={{top: insets.top}}>
                    <TouchableOpacity onPress={onPressBack}>
                        <ArrowLeft width={12} height={12} fill="white"/>
                    </TouchableOpacity>
                </Flex>;
            }

            return <Flex className="h-12 absolute left-5" verticalAlign="center" align="center" style={{top: insets.top}}>
                <TouchableOpacity onPress={() => {
                    if (navigation.canGoBack()) {
                        navigation.goBack();
                        return;
                    }

                    navigation.dispatch(CommonActions.reset({
                        index: 0,
                        routes: [{name: 'Home'}],
                    }))
                }}>
                    <ArrowLeft width={12} height={12} fill="white"/>
                </TouchableOpacity>
            </Flex>;
        }

        return null;
    };

    return (
        <Flex
            align="between"
            verticalAlign="center"
            className={`${Platform.OS === "web" && deviceType == DeviceType.DESKTOP ? "w-[500px]" : "w-screen"} px-5 ${background}`}
            style={{paddingTop: insets.top}}
            {...props}
        >
            <Flex className="h-12" verticalAlign="center" align="center">
                <Text type="base-semibold" color={color}>{props.title}</Text>
            </Flex>
            {renderBackFunction()}
        </Flex>
    );
}