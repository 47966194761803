import React, {useState} from "react";
import Flex from "@components/Views/Flex";
import Header from "@components/Views/Header";
import ContentTabs from "@components/General/ContentTabs";
import {AuthStackParamList} from "@src/routes";
import {StackScreenProps} from "@react-navigation/stack";
import FilmupAccount from "@pages/SignedIn/Settings/components/FilmupAccount";
import PersonalInfo from "@pages/SignedIn/Settings/components/PersonalInfo";

type SettingsProps = StackScreenProps<AuthStackParamList, 'Settings'>;
export default function Settings({route}: SettingsProps) {

    const [selectedTab, setSelectedTab] = useState<string>(route.params.tab);

    return (
        <Flex className="flex-1 bg-white">
            <Header title="Settings" background="bg-denim-light-100"/>
            <ContentTabs
                align="start"
                values={["Filmup Account", "Personal Info"]}
                defaultSelected={selectedTab}
                onSelected={setSelectedTab}
                background="bg-denim-light-100"
                btnBackground="bg-navy-light-100"
            />

            {selectedTab === "filmup-account" && <FilmupAccount/>}
            {selectedTab === "personal-info" && <PersonalInfo/>}
        </Flex>
    );
}