import {ActivityIndicator, ScrollView, TouchableOpacity, View} from "react-native";
import Flex from "@components/Views/Flex";
import Text from "@components/General/Text";
import FlexRow from "@components/Views/FlexRow";
import {transformTailwindToHex} from "@src/helpers";
import Button from "@components/Buttons/Button";
import React, {useEffect, useState} from "react";
import productionApi from "@api/productionApi";
import CheckRounded from "@assets/icons/check-rounded.svg";

import RNPickerSelect from 'react-native-picker-select';
import FloatingBottom from "@components/Views/FloatingBottom";

export default function IdVerification({offerId, nextStep}) {

    const [loading, setLoading] = useState<boolean>(true);

    const [documentListA, setDocumentListA] = useState<any>([]);
    const [documentListASelected, setDocumentListASelected] = useState<number|null>(null);

    const [documentListB, setDocumentListB] = useState<any>([]);
    const [documentListBSelected, setDocumentListBSelected] = useState<number|null>(null);

    const [documentListC, setDocumentListC] = useState<any>([]);
    const [documentListCSelected, setDocumentListCSelected] = useState<number|null>(null);

    useEffect(() => {
        getPotentialI9Documents()
    }, [])

    const getPotentialI9Documents = async () => {
        setLoading(true);

        let response = await productionApi.getPotentialI9Documents(offerId);
        setDocumentListA(response.data['list-a']);
        setDocumentListB(response.data['list-b']);
        setDocumentListC(response.data['list-c']);

        setLoading(false);
    };

    const confirmSelection = () => {
        let documentsToUpload = {
            listA: documentListASelected != null ? documentListA[documentListASelected] : null,
            listB: documentListBSelected != null ? documentListB[documentListBSelected] : null,
            listC: documentListCSelected != null ? documentListC[documentListCSelected] : null,
        };
        nextStep(documentsToUpload);
    }

    return (
            <>
                <Flex className="flex-1 mt-5" verticalAlign="between">
                    <ScrollView className="flex-1 px-5">
                        <Flex className="flex-1">

                            {loading && <ActivityIndicator className="mt-5"/>}

                            {!loading && <Flex className={((documentListBSelected == null || documentListBSelected == -1) && (documentListCSelected == null || documentListCSelected == -1)) ? "" : "opacity-30"}>
                                <FlexRow verticalAlign="center" align="between">
                                    <Text type="xs-semibold" color="navy-light-100" className="mb-3">Option A</Text>
                                    <Text type="xs-medium" color="navy-light-100" className="mb-3">(Select only 1)</Text>
                                </FlexRow>

                                {documentListA.map((document, index) =>
                                    <TouchableOpacity onPress={() => {
                                        if ((documentListBSelected == null || documentListBSelected == -1) && (documentListCSelected == null || documentListCSelected == -1)) {
                                            setDocumentListASelected(documentListASelected == index ? null : index)
                                        }
                                    }}>
                                        <FlexRow className="py-3.5 border-b-0.5 border-denim-20" verticalAlign="center">
                                            {documentListASelected == index && <CheckRounded width={16} height={16} fill={transformTailwindToHex("navy-light-100")}/>}
                                            {documentListASelected != index && <View className="h-4 w-4 border border-navy-light-100 rounded-full"/>}

                                            {/* @TODO: Create a helper */}
                                            <Flex className="ml-4">
                                                <Text type="xs-medium">
                                                    {document.title.slice(0, 40)}
                                                    {document.title.length > 40 ? "..." : ""}
                                                </Text>
                                            </Flex>
                                        </FlexRow>
                                    </TouchableOpacity>
                                )}
                            </Flex>}


                            {!loading && <Flex className={documentListASelected != null ? "opacity-30" : ""}>
                                <FlexRow verticalAlign="center" align="between" className="mt-10">
                                    <Text type="xs-semibold" color="navy-light-100" className="mb-3">Option B</Text>
                                    <Text type="xs-medium" color="navy-light-100" className="mb-3">(Select 1 of each)</Text>
                                </FlexRow>

                                {/* List of documents */}
                                <FlexRow className={`bg-navy-light-100/5 rounded-xl py-3 mb-2`}>
                                    <RNPickerSelect
                                            disabled={documentListASelected != null}
                                            placeholder={{label: "1. Select Identity Verification", value: -1}}
                                            onValueChange={(value) => setDocumentListBSelected(value)}
                                            items={documentListB.map((doc, index) => {
                                                return {label: doc.title, value: index}
                                            })}
                                            style={{
                                                placeholder: {
                                                    color: transformTailwindToHex("denim-100")
                                                },
                                                inputWeb: {
                                                    backgroundColor: "transparent",
                                                    paddingLeft: 12
                                                }
                                            }}
                                    />
                                </FlexRow>
                                <FlexRow className={`bg-navy-light-100/5 rounded-xl py-3 mb-2`}>
                                    <RNPickerSelect
                                            disabled={documentListASelected != null}
                                            placeholder={{label: "2. Select Employment Authorization", value: -1}}
                                            onValueChange={(value) => {
                                                setDocumentListCSelected(value)
                                                console.log(value)
                                            }}
                                            items={documentListC.map((doc, index) => {
                                                return {label: doc.title, value: index}
                                            })}
                                            style={{
                                                placeholder: {
                                                    color: transformTailwindToHex("denim-100")
                                                },
                                                inputWeb: {
                                                    backgroundColor: "transparent",
                                                    paddingLeft: 12,
                                                    fontSize: 15
                                                }
                                            }}
                                    />
                                </FlexRow>
                            </Flex>}

                        </Flex>
                    </ScrollView>
                    {(documentListASelected != null ||
                            (documentListBSelected != null && documentListCSelected != null && documentListBSelected != -1 && documentListCSelected != -1)
                        ) &&
                        <FloatingBottom>
                            <Button
                                useFullColor={true}
                                value="Next"
                                color="navy-light-100"
                                className="mt-2"
                                onPress={() => confirmSelection()}
                            />
                        </FloatingBottom>
                    }
                </Flex>
            </>
    )
}