import React, {useState} from "react";
import {ScrollView, TouchableOpacity,} from "react-native";

import productionApi from "@api/productionApi";

import Flex from "@components/Views/Flex";
import FlexRow from "@components/Views/FlexRow";
import Tag from "@components/General/Tag";
import Text from "@components/General/Text";
import {formatDateDefault} from "@src/helpers";
import {RefreshControl} from 'react-native-web-refresh-control'
import NoData from "@components/General/NoData";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {useFocusEffect, useNavigation} from "@react-navigation/native";
import {navigationProps} from "@interfaces/RoutesInterface";
import {productionType} from "@interfaces/ProductionInterface";

type InvoiceTabProps = {
    production: productionType
}
export default function InvoiceTab({production}: InvoiceTabProps) {
    const navigation = useNavigation<navigationProps>();

    const [invoices, setInvoices] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const insets = useSafeAreaInsets();

    useFocusEffect(
        React.useCallback(() => {
            getInvoices();
        }, [])
    );

    const getInvoices = async () => {
        setLoading(true);

        let response = await productionApi.getTimecardList();
        setInvoices(response.data);

        setLoading(false);
    };

    return (
        <Flex className="flex-1 px-5">
            <ScrollView
                className="flex-1"
                style={{paddingBottom: insets.bottom == 0 ? 20 : insets.bottom}}
                refreshControl={
                    <RefreshControl refreshing={loading} onRefresh={getInvoices}/>
                }>

                {invoices.length == 0 && !loading && <NoData text="You don't have any invoices yet."/>}

                {invoices.length > 0 && !loading && invoices.map((invoice, index) => {
                    return (
                        <TouchableOpacity key={index} onPress={() => {
                            navigation.navigate("Production", {productionId: invoice.related.production.data.id, tab: "invoices"});
                        }}>
                            <Flex className="border-b border-gray-200 py-4">
                                <FlexRow verticalAlign="center" align="between" className="mb-2">
                                    <Text type="sm-medium">{invoice.related.production.data.attributes.name}</Text>
                                    <Tag type={invoice.attributes.status}/>
                                </FlexRow>
                                <FlexRow verticalAlign="center" align="between" className="mb-1">
                                    <Text type="xs-medium" color="denim-50">Week {invoice.id.replace("-", " of ")}</Text>
                                    <Text type="xs-medium" color="denim-50">{formatDateDefault(invoice.attributes.start_date)} - {formatDateDefault(invoice.attributes.end_date)}</Text>
                                </FlexRow>
                            </Flex>
                        </TouchableOpacity>
                    );
                })}
                <TouchableOpacity onPress={() => {
                    navigation.navigate("ProductionInvoice", {productionId: production.id});
                }}>
                    <Flex className="border-b border-gray-200 py-4">
                        <FlexRow verticalAlign="center" align="between" className="mb-2">
                            <Text type="sm-medium">Add New Invoice</Text>
                        </FlexRow>
                    </Flex>
                </TouchableOpacity>
            </ScrollView>
        </Flex>
    );
}