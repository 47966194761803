import {ActivityIndicator, ScrollView} from "react-native";
import Flex from "@components/Views/Flex";
import Button from "@components/Buttons/Button";
import React, {useState} from "react";
import Text from "@components/General/Text";
import Content from "@components/General/Content";
import TextInput from "@components/Forms/TextInput";
import FlexRow from "@components/Views/FlexRow";
import FloatingBottom from "@components/Views/FloatingBottom";

type ReviewProps = {
    productionId: string,
}
export default function Review({productionId}: ReviewProps) {
    const [loading, setLoading] = useState<boolean>(false);

    return (
        <Flex className="flex-1 mt-5" verticalAlign="between">
            <ScrollView className="flex-1 px-5">
                <Flex className="flex-1">

                    {loading && <ActivityIndicator className="mt-5"/>}

                    <Flex>
                        <Text type="xs-medium" color="denim-50" className="mb-2">Additional Documents</Text>
                        <Text type="sm-medium" color="denim-50">May 23 - May 29, 2024</Text>
                    </Flex>

                    <Content title="Work Hours" className="mt-8">
                        {[1, 2, 3, 4, 5, 6, 7].map((invoice, index) => {
                            return (
                                <FlexRow key={index} className="h-11 border-b-0.5 border-denim-20" verticalAlign="center" align="between">
                                    <FlexRow className="w-20 mb-3" verticalAlign="center" align="between">
                                        <Text type="xs-semibold" color="navy-100">May 23</Text>
                                        <Text type="xs-medium" color="denim-50">Sun</Text>
                                    </FlexRow>
                                    <Text type="sm-medium" color="denim-100">8.00h</Text>
                                </FlexRow>
                            )
                        })}
                    </Content>

                    <Content title="Other Payments & Expenses" className="mt-8">
                        {[1, 2, 3].map((invoice, index) => {
                            return (
                                <FlexRow verticalAlign="center" align="between" className="h-11 border-b-0.5 border-denim-20">
                                    <FlexRow verticalAlign="center" align="start" className="flex-grow">
                                        <Text type="xs-semibold" color="navy-100">May 23</Text>
                                        <Text type="xs-medium" color="denim-50" className="ml-2">Bonus</Text>
                                    </FlexRow>
                                    <Text type="xs-semibold" color="denim-100">$150.00</Text>
                                </FlexRow>
                            );
                        })}

                        <Text type="xs-medium" color="navy-light-100" className="text-right mt-2">Total Payments: $0.00</Text>
                    </Content>

                    <Content title="Additional Materials" className="mt-8">
                        <TextInput label="Add Note"
                                   value={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, do eiusmod tempor incididunt ut labore et dolore magna aliqua. Non pulvinar neque laoreet suspendisse interdum consectetur libero."}
                                   onChange={() => null} bigText={true}/>

                        <FlexRow className="rounded-xl px-3 py-4 mb-2 border border-dashed border-denim-20 bg-navy-light-100/5" align="between" verticalAlign="center">
                            <Text type="sm-medium" color="navy-light-100">parking_validation_receipt.png</Text>
                            <Text type="xs-medium" color="navy-light-100">Edit</Text>
                        </FlexRow>

                        <FlexRow className="rounded-xl px-3 py-4 mb-2 border border-dashed border-denim-20 bg-navy-light-100/5" align="between" verticalAlign="center">
                            <Text type="sm-medium" color="navy-light-100">parking_validation_receipt.png</Text>
                            <Text type="xs-medium" color="navy-light-100">Edit</Text>
                        </FlexRow>
                    </Content>

                </Flex>
            </ScrollView>
            <FloatingBottom>
                <Button
                    useFullColor={true}
                    value="Submit Invoice"
                    color="navy-light-100"
                    className="mt-2"
                    onPress={() => null}
                />
            </FloatingBottom>
        </Flex>
    )
}