import React, {useState} from "react";
import {ScrollView, TouchableOpacity,} from "react-native";

import productionApi from "@api/productionApi";

import Flex from "@components/Views/Flex";
import FlexRow from "@components/Views/FlexRow";
import Tag from "@components/General/Tag";
import Text from "@components/General/Text";
import {formatDateDefault} from "@src/helpers";
import {RefreshControl} from 'react-native-web-refresh-control'
import NoData from "@components/General/NoData";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {useFocusEffect, useNavigation} from "@react-navigation/native";
import {navigationProps} from "@interfaces/RoutesInterface";

export default function OffersHistory() {
    const [offers, setOffers] = useState<any>([]); // @TODO: Fix this type
    const [loading, setLoading] = useState<boolean>(true);
    const navigation = useNavigation<navigationProps>();
    const insets = useSafeAreaInsets();

    useFocusEffect(
        React.useCallback(() => {
            getOffers();
        }, [])
    );

    const getOffers = async () => {
        setLoading(true);

        let response = await productionApi.getCrewOffers();
        setOffers(response.data);

        setLoading(false);
    };

    return (
        <Flex className="flex-1 px-5">
            <ScrollView
                className="flex-1"
                style={{paddingBottom: insets.bottom == 0 ? 20 : insets.bottom}}
                refreshControl={
                    <RefreshControl refreshing={loading} onRefresh={getOffers}/>
                }>

                {offers.length == 0 && !loading && <NoData text="You don't have any offers yet."/>}

                {offers.length > 0 && !loading && offers.map((offer, index) => {
                    return (
                        <TouchableOpacity key={index} onPress={() => {
                            navigation.navigate("Offer", {offerId: offer.id});
                        }}>
                            <Flex className="border-b border-gray-200 py-4">
                                <FlexRow verticalAlign="center" align="between" className="mb-2">
                                    <Text type="sm-medium">{offer.attributes.role}</Text>
                                    <Tag type={offer.attributes.status}/>
                                </FlexRow>
                                <FlexRow verticalAlign="center" align="between" className="mb-1">
                                    <Text type="xs-medium" color="denim-50">{offer.attributes.production_name}</Text>
                                    <Text type="xs-medium" color="denim-50">{formatDateDefault(offer.attributes.first_shooting_day)} - {formatDateDefault(offer.attributes.last_shooting_day)}</Text>
                                </FlexRow>
                            </Flex>
                        </TouchableOpacity>
                    );
                })}
            </ScrollView>
        </Flex>
    );
}