import React, {useEffect, useState} from "react";
import TextInput from "@components/Forms/TextInput";
import CurrencyInputStyled from "@components/Forms/CurrencyInput";
import Text from "@components/General/Text";
import Flex from "@components/Views/Flex";
import DateInput from "@components/Forms/DateInput";
import TravelTime from "@pages/SignedIn/ProductionPayment/components/TravelTime";
import FlexRow from "@components/Views/FlexRow";
import {toTwoDecimal} from "@src/helpers";

type travelTimeProps = {
    start_time: string,
    end_time: string,
}

export default function Mileage({data, onUpdateData}) {

    const [totalMiles, setTotalMiles] = useState<number | null>(null);
    const [tripDate, setTripDate] = useState<string>("");
    const [destination, setDestination] = useState<string>("");
    const [travelTime, setTravelTime] = useState<travelTimeProps|null>(null);

    useEffect(() => {
        if (data.amount !== undefined) {
            setPaymentAmount(data.amount);
        }

        if (data.date !== undefined) {
            setTripDate(data.date);
        }

        if (data.extra !== undefined) {
            setDestination(data.extra.destination);
            setTravelTime(data.extra.travel_time);
        }
    }, []);

    useEffect(() => {
        onUpdateData({
            date: tripDate,
            amount: (totalMiles ?? 0)*0.67,
            extra: {
                destination: destination,
                travel_time: travelTime,
                total_miles: totalMiles
            }
        });

    }, [totalMiles, tripDate, destination, travelTime]);

    return <Flex>
        <DateInput label="Trip Date" value={tripDate} defaultValue={tripDate} onChange={setTripDate}/>

        <TravelTime
            label={"Travel Time"}
            value={travelTime}
            disabled={false}
            updateValue={(value) => setTravelTime(value)}
        />

        <TextInput label="Destination" value={destination} onChange={setDestination}/>

        <Flex>
            <CurrencyInputStyled label="Total Miles" value={totalMiles} onChange={setTotalMiles}/>
            <Flex className="absolute top-4 right-10">
                <Text type="xs-medium" color="denim-50">$0.67 / Mile</Text>
            </Flex>
        </Flex>

        <FlexRow verticalAlign="center" align="between" className="w-full">
            <Text type="sm-medium" color="denim-50" className="my-5">Total Payment:</Text>
            <Text type="sm-medium" color={totalMiles == null ? "coral-100" : "navy-light-100"} className="my-5">${toTwoDecimal(0.67*(totalMiles ?? 0))}</Text>
        </FlexRow>
    </Flex>;
}