import React from "react";

import FlexRow from "@components/Views/FlexRow";
import Text from "@components/General/Text";
import Flex from "@components/Views/Flex";

type ContentTableProps = {
    rowAmount?: 1|2|3,
    borders?: boolean,
    values: {
        title: string,
        value: string | null | number | undefined | React.ReactNode,
        valueType?: string,
    }[],
    className?: string,
};
export default function ContentTable({rowAmount = 2, borders = false, values, ...props}: ContentTableProps) {

    const rows = Math.ceil(values.length/rowAmount);

    const align = (index: number, amountInObject: number) => {
        if (index == 0) return "start";
        if (index == amountInObject - 1) return "end";
        return "center";
    }
    const border = (index: number, amountInObject: number) => {
        if (index == 0 || !borders) return "";
        return "border-l border-denim-100/10";
    }

    const getValues = (row: number) => {
        return values.slice(row*rowAmount, row*rowAmount + rowAmount)
    }

    const renderRows = () => {
        var output=[];

        for (let i = 0; i < rows; i += 1) {

            output.push(
                <FlexRow verticalAlign="start" align="between" className="mb-3" key={`row-${i}`}>
                    {getValues(i).map((obj, index) =>
                        <Flex align={align(index, getValues(i).length)} className={`flex-1 ${border(index, getValues(i).length)}`} key={index}>
                            <Text type="xs-medium" color="denim-50">{obj.title}</Text>
                            {!obj.valueType && <Text type="sm-medium">{obj.value ?? "-"}</Text>}
                            {obj.valueType == "node" && obj.value}
                        </Flex>
                    )}
                </FlexRow>
            )
        }

        return output;
    }

    return <Flex className="w-full" {...props}>
        {renderRows()}
    </Flex>
}