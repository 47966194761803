import React, {useEffect, useState} from "react";
import {ActivityIndicator, ScrollView, TouchableOpacity,} from "react-native";

import Flex from "@components/Views/Flex";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import Text from "@components/General/Text";
import FlexRow from "@components/Views/FlexRow";
import {capitalizeFirstLetter, formatDate, formatDateDefault, formatDateDefaultYear, getDatesBetweenDates, isEmpty, translatedAmount} from "@src/helpers";
import productionApi from "@api/productionApi";
import Content from "@components/General/Content";
import SubContentTabs from "@components/General/SubContentTabs";
import TimeCardRow from "@pages/SignedIn/Production/components/TimecardTab/TimeCardRow";
import TimeCardMealRow from "@pages/SignedIn/Production/components/TimecardTab/TimeCardMealRow";
import {ShootType} from "@interfaces/ProductionInterface";
import Button from "@components/Buttons/Button";
import SecondaryButton from "@components/Buttons/SecondaryButton";
import {useFocusEffect, useNavigation} from "@react-navigation/native";
import {navigationProps} from "@interfaces/RoutesInterface";
import FloatingBottom from "@components/Views/FloatingBottom";

export const getBonusStatusColor = (status: string) => {
    switch (status) {
        case "pending":
            return "orange-100";
        case "approved":
            return "emerald-100";
        case "rejected":
            return "coral-100";
        default:
            return "denim-50";
    }
}
export default function DayView({productionId, timecardId, date, goToWeekView}: { productionId: string, timecardId: string, date: string, goToWeekView: () => void }) {
    const insets = useSafeAreaInsets();
    const [selectedTab, setSelectedTab] = useState<string | null>(null);
    const [timecard, setTimecard] = useState<any>(null);
    const [shootDay, setShootDay] = useState<ShootType | null>(null);
    const [showDonePopup, setShowDonePopup] = useState<boolean>(true);
    const [dates, setDates] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const navigation = useNavigation<navigationProps>();

    useFocusEffect(
        React.useCallback(() => {
            setShowDonePopup(true);
        }, [])
    );

    useEffect(() => {
        getTimecard(timecardId);
    }, [timecardId]);

    useEffect(() => {
        getProductionDaysInWeek();
    }, [timecard]);

    useEffect(() => {
        getShootDay();
    }, [selectedTab]);

    const getTimecard = async (timecardId: string) => {
        setLoading(true);

        let response = await productionApi.getTimecard(timecardId);
        setTimecard(response.data);
        setSelectedTab(formatDate(date, "dddd").substring(0, 3));

        setLoading(false);
    };

    const getProductionDaysInWeek = () => {
        if (timecard == null) return;

        const datesInWeek = getDatesBetweenDates(timecard.attributes.start_date, timecard.attributes.end_date).map(date => formatDate(date, "YYYY-MM-DD"));
        const productionDays = timecard.attributes.times.map(time => time.date);

        setDates(datesInWeek.filter(date => productionDays.includes(date)));
    }

    const isMealCompleted = (meal: any, mealNumber: number) => {

        if (shootDay == null) return false;

        if (shootDay.attributes.required_meals_count == 0 || meal?.penalty) {
            return true;
        }

        if (shootDay.attributes.required_meals_count == 1 && mealNumber == 1) {
            return !isEmpty(meal?.start_time);
        }

        if (shootDay.attributes.required_meals_count == 2 && mealNumber == 2) {
            return !isEmpty(meal?.start_time);
        }

        return true;
    }

    const getShootDay = async () => {

        if (timecard == null || selectedTab == null) return;

        setLoading(true);

        let shootDayTimecardData = timecard.attributes.times.find((time: any) => {
            return formatDate(time.date, "dddd").substring(0, 3).toLowerCase() == selectedTab.toLowerCase()
        });

        const shootDayResponse: { data: ShootType } = await productionApi.getShoot(shootDayTimecardData?.id);

        setShootDay(shootDayResponse.data);
        setLoading(false);
    }

    if (loading || timecard == null || dates == null || shootDay == null) {
        return <ActivityIndicator className="mt-5"/>;
    }

    return <Flex className="flex-1">
        <SubContentTabs
            align="start"
            values={dates.map((date: any) => {
                return formatDate(date, "dddd").substring(0, 3)
            })}
            defaultSelected={selectedTab}
            onSelected={(value) => setSelectedTab(value)}
        />
        <ScrollView
            className="flex-1 px-5"
            style={{paddingBottom: insets.bottom == 0 ? 20 : insets.bottom}}>

            <Flex className="border-b-0.5 border-denim-20 -mx-5 px-5 py-5 gap-y-1">
                <FlexRow align="between">
                    <Text type="sm-semibold" color="navy-100">
                        {formatDateDefault(timecard.attributes.start_date)} - {formatDateDefaultYear(timecard.attributes.end_date)}
                    </Text>
                    <Text type="xs-medium" color="navy-100">
                        Day {shootDay.attributes.current_day} of {shootDay.attributes.total_days}
                    </Text>
                </FlexRow>

                <Text type="xs-normal" color="denim-50">
                    {shootDay.attributes.checkin?.address}
                </Text>
            </Flex>

            <TimeCardRow
                disabled={false}
                value={shootDay.attributes.checkin_at}
                label={"Time In"}
                locked={shootDay.attributes.is_locked}
                shootId={shootDay.id}
                completedBy={shootDay.attributes.checkin_by}
                type="checkin_time"
                updateTimeCard={(data) => setShootDay(data)}
            />

            <TimeCardMealRow
                disabled={false}
                required={shootDay.attributes.required_meals_count > 0}
                value={shootDay.attributes.meal1}
                label={"Meal 1"}
                locked={shootDay.attributes.is_locked}
                shootId={shootDay.id}
                completedBy={shootDay.attributes.meals_by}
                type="meal1"
                updateTimeCard={(data) => setShootDay(data)}
            />

            <TimeCardMealRow
                disabled={false}
                required={shootDay.attributes.required_meals_count == 2}
                value={shootDay.attributes.meal2}
                label={"Meal 2"}
                locked={shootDay.attributes.is_locked}
                shootId={shootDay.id}
                completedBy={shootDay.attributes.meals_by}
                type="meal2"
                updateTimeCard={(data) => setShootDay(data)}
            />

            <TimeCardRow
                disabled={false}
                value={shootDay.attributes.checkout_at}
                label={"Time Out"}
                locked={shootDay.attributes.is_locked}
                shootId={shootDay.id}
                completedBy={shootDay.attributes.checkout_by}
                type="checkout_time"
                updateTimeCard={(data) => setShootDay(data)}
            />

            <Content title="Other Payments & Expenses" className="mt-8">
                <Flex className="-mt-3">
                    {timecard.related.bonuses.data.map((expense: any) => {
                        return <FlexRow verticalAlign="center" align="between" className="h-11 border-b-0.5 border-denim-20">
                            <Text type="sm-medium" color="denim-50">{expense.attributes.type}</Text>
                            <FlexRow verticalAlign="center" align="end" className="gap-x-2">
                                <Text type="sm-medium" color="denim-100">{translatedAmount(expense.attributes.amount)}</Text>
                                <Text type="sm-medium" color={getBonusStatusColor(expense.attributes.status)}>{capitalizeFirstLetter(expense.attributes.status)}</Text>
                            </FlexRow>
                        </FlexRow>
                    })}
                    <TouchableOpacity onPress={() => navigation.navigate("ProductionPayment", {productionId: productionId, timecardId: timecardId})}>
                        <FlexRow className="h-11 border-b-0.5 border-denim-20" verticalAlign="center" align="start">
                            <Text type="sm-medium" color="denim-50">Add Payments</Text>
                        </FlexRow>
                    </TouchableOpacity>
                </Flex>
            </Content>

        </ScrollView>

        {showDonePopup && !isEmpty(shootDay.attributes.checkin_at) && !isEmpty(shootDay.attributes.checkout_at) &&
            isMealCompleted(shootDay.attributes.meal1, 1) && isMealCompleted(shootDay.attributes.meal2, 2) &&
            <FloatingBottom>
                <Text type="sm-medium" color="navy-100">Timecard Complete!</Text>
                <SecondaryButton value="Keep Editing" className="mt-5 mb-2" onPress={() => setShowDonePopup(false)}/>
                <Button useFullColor={true} value="Go To Weekly View" color="navy-light-100" onPress={() => goToWeekView()}/>
            </FloatingBottom>
        }
    </Flex>
}