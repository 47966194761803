import api from './index';

const userApi = {
    async login(data: object) {
        return await api.post("/login", data);
    },

    async signup(data: object) {
        return await api.post("/signup", data);
    },

    async updateCrewDataForOffer(data: object, offerId: string) {
        return await api.put(`/offers/${offerId}`, data);
    },

    async getAccount() {
        return await api.get("/account");
    },

    async putAccount(data: object) {
        return await api.put("/account", data);
    },

    async putCompanyContact(id: string, data: object) {
        return await api.put("/account/companies/"+id, data);
    }
}

export default userApi;