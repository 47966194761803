import {
    Text as RNText
} from "react-native";
import React from "react";

type TextProps = {
    type?: string,
    color?: string,
    className?: string,
    children: React.ReactNode,
};

const types: Record<string, string> = {
    "2xl-semibold": "text-2xl font-semibold", // 24px 600
    "xl-black": "text-xl font-black", // 20px 900
    "xl-bold": "text-xl font-bold", // 20px 700
    "xl-semibold": "text-xl font-semibold", // 20px 600
    "lg-black": "text-lg font-black", // 18px 900
    "lg-bold": "text-lg font-bold", // 18px 700
    "lg-semibold": "text-lg font-semibold", // 18px 700
    "base-black": "text-base font-black", // 16px 900
    "base-bold": "text-base font-bold", // 16px 700
    "base-semibold": "text-base font-semibold", // 16px 600
    "base-medium": "text-base font-medium", // 16px 500
    "base-normal": "text-base font-normal", // 16px 400
    "sm-black": "text-[15px] font-black", // 14px 900
    "sm-bold": "text-[15px] font-bold", // 14px 700
    "sm-semibold": "text-[15px] font-semibold", // 14px 600
    "sm-medium": "text-[15px] font-medium", // 14px 500
    "sm-normal": "text-[15px] font-normal", // 14px 400
    "xs-bold": "text-[13px] font-bold", // 12px 700
    "xs-semibold": "text-[13px] font-semibold", // 12px 600
    "xs-medium": "text-[13px] font-medium", // 12px 500
    "xs-normal": "text-[13px] font-normal", // 12px 400
    "2xs-normal": "text-[10px] font-normal", // 10px 400
    "3xs-normal": "text-[8px] font-normal", // 8px 400
}

export default function Text({color = "denim-100", ...props}: TextProps) {

    let customClass = "";

    if (props.type) {
        customClass = types[props.type];
    }

    return <RNText className={`${customClass} text-${color}`} {...props}>
        {props.children}
    </RNText>;
}