import React, {useEffect, useState} from "react";
import {Platform, TouchableOpacity} from "react-native";
import FlexRow from "@components/Views/FlexRow";
import Upload from "@assets/icons/upload.svg";
import TextInput from "@components/Forms/TextInput";
import DateInput from "@components/Forms/DateInput";
import CurrencyInputStyled from "@components/Forms/CurrencyInput";
import Text from "@components/General/Text";
import Flex from "@components/Views/Flex";
import {toTwoDecimal, transformTailwindToHex} from "@src/helpers";
import * as ImagePicker from 'expo-image-picker';

export default function Default({data, onUpdateData}) {

    const [paymentDocument, setPaymentDocument] = useState<any>(null);
    const [paymentAmount, setPaymentAmount] = useState<number | null>(null);
    const [paymentDate, setPaymentDate] = useState<string>("");
    const [paymentNotes, setPaymentNotes] = useState<string>("");

    useEffect(() => {
        if (data.amount !== undefined) {
            setPaymentAmount(data.amount);
        }

        if (data.date !== undefined) {
            setPaymentDate(data.date);
        }

        if (data.note !== undefined) {
            setPaymentNotes(data.note);
        }
    }, []);

    useEffect(() => {
        onUpdateData({
            amount: paymentAmount,
            date: paymentDate,
            note: paymentNotes,
            payment_document: paymentDocument
        });

    }, [paymentDocument, paymentAmount, paymentDate, paymentNotes]);

    const pickDocument = async () => {

        let photo = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            aspect: [4, 3],
            quality: 1,
            base64: false
        });

        if (Platform.OS != "web") {
            setPaymentDocument({
                name: photo.fileName,
                type: photo.type,
                uri: Platform.OS === 'ios' ? photo.uri.replace('file://', '') : photo.uri,
            });

        } else {
            const res = await fetch(photo.uri);
            const blob = await res.blob();

            setPaymentDocument(blob);
        }
    }

    return <Flex>
        <DateInput label="Transaction Date" value={paymentDate} defaultValue={paymentDate} onChange={setPaymentDate}/>

        <Flex>
            <Flex className="absolute top-4 left-3"><Text type="xs-medium" color="denim-50">$</Text></Flex>
            <CurrencyInputStyled label="Payment Amount" value={paymentAmount} onChange={setPaymentAmount} paddingLeft="pl-6"/>
        </Flex>

        <TextInput label="Add Note" value={paymentNotes} onChange={setPaymentNotes} bigText={true}/>

        <TouchableOpacity onPress={() => pickDocument()}>
            {paymentDocument == null &&
                <FlexRow
                    className="rounded-xl px-3 py-4 mb-4 border border-dashed border-denim-20 bg-coral-5"
                    align="center" verticalAlign="center">
                    <Upload width={16} height={16} fill={transformTailwindToHex("coral-100")}/>
                    <Text type="xs-medium" color="coral-100" className="ml-2">Add Document</Text>
                </FlexRow>
            }

            {paymentDocument != null &&
                <FlexRow
                    className="rounded-xl px-3 py-4 mb-4 border border-dashed border-denim-20 bg-navy-light-100/5"
                    align="center" verticalAlign="center">
                    <Upload width={16} height={16} fill={transformTailwindToHex("navy-light-100")}/>
                    <Text type="xs-medium" color="navy-light-100" className="ml-2">Upload Complete!</Text>
                </FlexRow>
            }
        </TouchableOpacity>

        <FlexRow verticalAlign="center" align="between" className="w-full">
            <Text type="sm-medium" color="denim-50" className="my-5">Total Payment:</Text>
            <Text type="sm-medium" color={paymentAmount == null ? "coral-100" : "navy-light-100"} className="my-5">${toTwoDecimal(paymentAmount ?? 0)}</Text>
        </FlexRow>
    </Flex>;
}